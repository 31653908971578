import {
  ref,
  onMounted,
  getCurrentInstance,
  watch
} from 'vue'
import {
  transScheduleBusUseCase
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'
import {
  useRouter, useRoute
} from 'vue-router'
import moment from 'moment'
import ScheduleBusForm from '@/views/transactions/schedule-bus/ScheduleBusForm.vue'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'

export default {
  name: 'ScheduleBus',
  components: {
    ScheduleBusForm
  },
  setup() {
    const dataSource = ref([])

    const op = ref()
    const sortOp = ref()
    const filterCal = ref()

    const router = useRouter()
    const route = useRoute()
    const inActionForm = ref()
    const store = useStore()
    const app = getCurrentInstance()
    const searchInput = ref(null)
    const sendSearchInput = ref('')
    const {
      $confirm,
      $toast,
      $strInd,
      $numbers,
      $swal
    } = app!.appContext.config.globalProperties
    const filter = {
      top: $numbers.totalItemPerPage,
      skip: 0,
      totalRecords: 0
    }
    const sendDateFilter: any = ref([moment().subtract(3, 'months'), moment()])
    const filters = {
      pageNumber: 1,
      pageSize: 10,
      totalRecords: 0,
      search: '',
      filterField: ['code'],
      custom: [
        ['activeDateStart', '=', moment(sendDateFilter.value[0]).format('YYYY-MM-DD')],
        ['activeDateEnd', '=', moment(sendDateFilter.value[1]).format('YYYY-MM-DD')],
        ['orderBy', '=', 'ActiveDate desc']
      ] as any
    }
    const dataForm = ref(null)
    const sliderActive = ref(false)

    const selectValue: any = ref('')
    const selectOption = [
      { name: 'RIT 1', value: '1' },
      { name: 'RIT 2', value: '2' }
    ]

    const sortingValue = ref('ActiveDate desc')
    const sortingOption = [
      { name: 'Tanggal Aktif', value: 'ActiveDate desc', key: 'sel1' },
      { name: 'Kode Jadwal', value: 'Code desc', key: 'sel2' }
    ]

    const dateFilter = ref([])
    /* eslint-disable consistent-return */
    /* eslint-disable no-useless-return */
    /* eslint no-else-return: ["error", {allowElseIf: true}] */
    const filterRit = () => {
      if (selectValue.value.length === 1) {
        return `Rit in ('${selectValue.value[0]}') and `
      } else if (selectValue.value.length === 2) {
        return `Rit in ('${String(selectValue.value[0])}','${String(selectValue.value[1])}') and `
      } return ''
    }

    const filterTanggal = () => {
      if (sendDateFilter.value !== null && sendDateFilter.value.length > 1) {
        return `TanggalAktif gt ${moment(sendDateFilter.value[0]).subtract(1, 'days').format('YYYY-MM-DD')}T00:00:00Z and TanggalAktif lt ${moment(sendDateFilter.value[1]).format('YYYY-MM-DD')}T00:00:00Z and `
      } return ''
    }

    const getDataSource = async () => {
      store.dispatch('showLoading')
      const response = await transScheduleBusUseCase.getAll(returnUrlPrams(filters))
      filters.totalRecords = response.result.Count
      dataSource.value = response.result.Data
      // console.log('data', dataSource)
      // console.log('reponse dat a bus ', response)
      store.dispatch('hideLoading')
    }
    const onPage = (event: any) => {
      filters.pageNumber = event.page + 1
      getDataSource()
    }
    const showDropDown = (evt: any, data: any, inActionDrop: any) => {
      // console.log(evt)
      dataForm.value = data
      if (data === null) {
        if (inActionDrop === 'sort') {
          sortOp.value.toggle(evt)
        } else {
          filterCal.value.toggle(evt)
        }
      } else {
        op.value.toggle(evt)
      }
    }
    const editData = (val: any) => {
      // console.log('val', val)
      const now = moment(moment().format('YYYY-MM-DD'))
      const activeDate = moment(moment(val.TanggalAktif).format('YYYY-MM-DD'))
      if (activeDate >= now) {
        $swal.fire({
          text: 'Anda akan mengubah penjadwalan bus yang sedang aktif. Tetap lanjutkan?',
          title: 'Edit Penjadwalan Bus',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#ff9800',
          cancelButtonColor: '#F44336',
          allowOutsideClick: false,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Kembali',
        }).then(async (result: any) => {
          // console.log('resultcompo', result)
          if (result.isConfirmed) {
            inActionForm.value = 'editData'
            dataForm.value = val
            sliderActive.value = true
          }
        })
      } else {
        inActionForm.value = 'editData'
        dataForm.value = val
        sliderActive.value = true
      }
    }
    const copyData = (val: any) => {
      inActionForm.value = 'duplicateData'
      dataForm.value = val
      sliderActive.value = true
    }
    const redirectAdd = () => {
      inActionForm.value = 'addData'
      dataForm.value = null
      sliderActive.value = true
    }
    const closeSidebar = () => {
      sliderActive.value = false
    }
    const deleteData = (Id: number) => {
      $confirm.require({
        header: 'Penjadwalan',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const response = await transScheduleBusUseCase.deleteData(Id)
          if (response.error) {
            $toast.add({
              severity: 'error',
              detail: $strInd.toast.errorDelete,
              group: 'bc',
              life: 3000
            })
          } else {
            $toast.add({
              severity: 'success',
              detail: $strInd.toast.successDeleteData,
              group: 'bc',
              life: 3000
            })
            getDataSource()
          }
        }
      })
    }

    const changeSorting = async (val: any) => {
      // console.log('val sorting', val)
      sortingValue.value = await val
      filters.custom.map(async (x: any, index: any) => {
        if (x[0] === 'orderBy') {
          filters.custom[index][2] = sortingValue.value
        }
      })
      getDataSource()
    }

    const activated = async (val: any) => {
      store.dispatch('showLoading')
      const data = {
        Id: val.Id,
        IsActive: val.IsActive
      }
      const response = await transScheduleBusUseCase.changeIsActive(data)
      // console.log('response active', response)
      if (response.error) {
        val.IsActive = !val.IsActive
        $toast.add({
          severity: 'error',
          detail: $strInd.toast.errorChangeStatus,
          group: 'bc',
          life: 1500
        })
      } else {
        $toast.add({
          severity: 'success',
          detail: 'Berhasil merubah status aktif',
          group: 'bc',
          life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    const changeIsActive = (val: any) => {
      $confirm.require({
        header: 'Status Aktif',
        message: $strInd.confirm.msgChange,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          activated(val)
        },
        reject: () => {
          val.IsActive = !val.IsActive
        }
      })
    }

    const detailData = (val: any) => {
      router.push({
        name: 'transaksi-jadwal-bus-detail',
        params: {
          idJadwal: val
        }
      })
    }

    const clearFilter = async () => {
      sendDateFilter.value = []
      // dateFilter.value = moment()
      getDataSource()
      filterCal.value.hide()
    }

    const searchChange = async (val: any) => {
      // sendSearchInput.value = await val.toLowerCase()
      filters.search = await val.toLowerCase()
      filters.pageNumber = 1
      // clearFilter()
      getDataSource()
    }

    const saveFilterDate = async () => {
      sendDateFilter.value = dateFilter.value
      if (sendDateFilter.value[1] !== null) {
        filters.custom.map(async (x: any, index: any) => {
          if (x[0] === 'activeDateStart') {
            filters.custom[index][2] = moment(sendDateFilter.value[0]).format('YYYY-MM-DD')
          } else if (x[0] === 'activeDateEnd') {
            filters.custom[index][2] = moment(sendDateFilter.value[1]).format('YYYY-MM-DD')
          }
        })
        filters.pageNumber = 1
        getDataSource()
        filterCal.value.hide()
      } else {
        $toast.add({
          severity: 'error',
          detail: 'Gunakan range tanggal untuk filter',
          group: 'bc',
          life: 3000
        })
      }
    }

    watch([selectValue], () => {
      if (selectValue.value) {
        const checkRitFilter = filters.custom.filter((x: any) => x[0] === 'rit')
        if (checkRitFilter.length > 0) {
          filters.custom.map(async (x: any, index: any) => {
            if (x[0] === 'rit') {
              filters.custom[index][2] = selectValue.value
            }
          })
        } else {
          filters.custom.push(['rit', '=', selectValue.value])
        }
        filters.search = ''
        filters.pageNumber = 1
        getDataSource()
      }
    })

    const backGroundCard = (val: any) => {
      let nameClass
      let viewText
      const now = moment(moment().format('YYYY-MM-DD'))
      const activeDate = moment(moment(val.ActiveDate).format('YYYY-MM-DD'))
      // if (moment(now).isAfter(activeDate)) {
      if (activeDate < now) {
        nameClass = 'isDone'
        viewText = '<div style="color:#2E7D32;" class="font-semibold">Selesai</div>'
      } else if (val.Attribute1 !== null) {
        nameClass = 'problem'
        viewText = '<div class="flex items-center" style="color:#F44336"><i class="pi pi-exclamation-triangle mr-2 font-semibold"></i><p class="font-semibold" style="color:#F44336">Terjadi Masalah</p></div>'
      } else if (!val.IsActive) {
        nameClass = 'background-white-darken-1'
        viewText = ''
      } else {
        nameClass = ''
        viewText = ''
      }
      return {
        nameClass,
        viewText
      }
    }

    // watch(dateFilter, (newVal, prevVal) => {
    //   console.log(`count is: ${newVal}`)
    // })

    onMounted(() => {
      getDataSource()
    })
    return {
      dataSource,
      filter,
      searchInput,
      sendSearchInput,
      store,
      searchChange,
      onPage,
      moment,
      showDropDown,
      op,
      dataForm,
      editData,
      copyData,
      deleteData,
      app,
      sliderActive,
      redirectAdd,
      closeSidebar,
      inActionForm,
      getDataSource,
      activated,
      detailData,
      router,
      route,
      changeIsActive,
      selectValue,
      selectOption,
      sortOp,
      sortingOption,
      sortingValue,
      changeSorting,
      filterCal,
      dateFilter,
      clearFilter,
      saveFilterDate,
      sendDateFilter,
      backGroundCard,
      filters
    }
  }
}
