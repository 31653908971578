import {
  dataEditAgen,
  transScheduleBusUseCase
} from '@/domain/usecase'
import {
  onMounted,
  computed,
  ref,
  getCurrentInstance,
  watch,
  defineComponent
} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import TabsDay from '@/views/transactions/delivery-item/create/steps-1/components/TabsDay.vue'
import DialogPriceCompanyRetail from '@/views/shared-components/dialog/DialogPriceCompanyRetail.vue'
import DialogSearchAgen from '@/views/shared-components/dialog/DialogSearchAgen.vue'
import moment from 'moment'
import { IMsAgen } from '@/utils/interfaces/iMsAgen'
import { checkPermission } from '@/utils/helpers/checkPermission'
import PermissionEnum from '@/utils/enum/permissions'

const modules = 'deliveryItem'

export default defineComponent({
  components: {
    TabsDay,
    DialogPriceCompanyRetail,
    DialogSearchAgen
  },
  setup() {
    const store = useStore()
    const app = getCurrentInstance()
    const profileAgent = ref(store.getters['appActiveUser/getAgent'])
    const fromAgent = `${profileAgent.value.kotaName} (${profileAgent.value.name})`
    const listSchedule = computed(() => store.state[modules].listScheduleBus)
    const toAgentId = computed(() => store.state[modules].toAgentId)
    const toAgentName = computed(() => store.state[modules].toAgentName)
    const toAgent = ref(toAgentId)
    const addressToAgent = computed(() => store.state[modules].addressToAgent)
    const scheduleId = computed(() => store.state[modules].scheduleId)
    const { $toast, $confirm } = app!.appContext.config.globalProperties
    const dialogPrice = ref(false)
    const toogleSAgen = ref(false)
    const router = useRouter()
    const toCityId = computed(() => store.state[modules].toCityId)
    const isMiisi = computed(() => store.state[modules].isMiisi)
    const filters = ref({
      custom: 'IsActive eq true',
      expand: '&$expand=TipeAgen($select=Tipe,Nama),Kota($select=Nama,Kode)',
      select: '&$select=Id,Alamat,KotaId,Nama,Kode,KodeResi',
      sorting: 'Kota/Nama asc'
    })

    const hideDialog = () => {
      dialogPrice.value = false
    }
    const toogleModalSearchAgen = () => {
      toogleSAgen.value = false
    }

    const activeStyle = (idSchedule: any) => {
      if (idSchedule === scheduleId.value) {
        return 'color-orange-darken-4'
      }
      return ''
    }

    const selectedSchedule = (id: any) => {
      store.dispatch(`${modules}/setScheduleId`, (scheduleId.value === id ? 0 : id))
    }

    const getScheduleBus = async (fromAgentId: any, idAgent: any, filterDay: any) => {
      // console.log('asadd')
      store.dispatch('showLoading')
      selectedSchedule(0)
      const scheduleFilters = `origAgentId=${fromAgentId}&destAgentId=${idAgent}&departureOffset=${filterDay}&pageSize=100&pageNumber=1`
      const response = await transScheduleBusUseCase.searchJadwalBus(`?${scheduleFilters}`)

      if (!response.error) {
        let index = 1
        store.dispatch(`${modules}/setListScheduleBus`, response.result.Data.map((val: any) => ({
          No: index++,
          Jadwal: val.Code,
          JadwalId: val.Id,
          JenisBus: val.BusCode,
          Keberangkatan: val.DepartureTime,
          Pendapatan: val.CurrentRevenue,
          Pengiriman: val.ArrivalTimeName,
          Rit: val.Rit,
          Attribute1: val.Attribute1
        })))
        store.dispatch('hideLoading')
      } else {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
      }
      // store.dispatch('hideLoading')
    }

    const checkData = () => {
      if (toAgentId.value && scheduleId.value) {
        store.dispatch(`${modules}/setCurrentIndex`, 1)
      } else {
        $toast.add({
          severity: 'error', detail: 'Agent tujuan/Jadwal harus dipilih terlebih dahulu', group: 'bc', life: 3000
        })
      }
    }
    const changeTabs = (val: any) => {
      const arrHeader = ['0d', '1d', '2d']
      store.dispatch(`${modules}/setKeyTabsSchedule`, val)
      store.dispatch(`${modules}/setParamsTabsSchedule`, arrHeader[val])
      getScheduleBus(profileAgent.value.id, store.state[modules].toAgentId, arrHeader[val])
    }

    const submitData = () => {
      checkData()
    }

    const submitBack = () => {
      $confirm.require({
        header: 'Pembatalan Pengiriman Barang',
        message: 'Apakah anda yakin ingin membatalkannya? Semua data yang diisi akan hilang.',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          router.push({ name: 'transaksi-pengiriman-barang' })
        }
      })
    }

    const getDetailAgen = (id: number) => {
      dataEditAgen(id, {
        select: 'Alamat, KotaId, Nama, Id',
        expand: 'Kota'
      })
        .then(({ error, result }) => {
          if (!error) {
            store.dispatch(`${modules}/setAddressToAgent`, result.Alamat)
            store.dispatch(`${modules}/setToCityId`, result.KotaId)
            store.dispatch(`${modules}/toAgentName`, result.Nama)
          }
        })
    }

    const getSearchAgenData = (val: IMsAgen) => {
      if (toAgentId.value !== val.Id) {
        store.dispatch(`${modules}/setToAgentId`, val.Id)
      }
    }

    watch(() => toAgentId.value, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        getDetailAgen(newValue)
        console.log('store', store.state[modules].tabsSchedule.params)
        getScheduleBus(profileAgent.value.id, newValue, store.state[modules].tabsSchedule.params)
      }
    })

    onMounted(() => {
      console.log('store', store.state)
      if (store.state[modules].typeDelivery === 'AD' || store.state[modules].typeDelivery === 'DD') {
        filters.value.custom = `${filters.value.custom} and IsKurirDooring eq true`
      }
    })
    return {
      fromAgent,
      listSchedule,
      toAgentId,
      toAgent,
      moment,
      scheduleId,
      dialogPrice,
      toogleSAgen,
      toCityId,
      addressToAgent,
      toAgentName,
      hideDialog,
      toogleModalSearchAgen,
      activeStyle,
      selectedSchedule,
      submitData,
      submitBack,
      changeTabs,
      getSearchAgenData,
      store,
      checkPermission,
      PermissionEnum,
      isMiisi
    }
  }
})
