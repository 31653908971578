/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import ICrud from '@/domain/usecase/iCrud'
import {
  fetchAllUsersCompany,
  fetchMinimalInfoUnverifiedUsersCompany,
  fetchMinimalInfoUsersCompany,
  fetchProfileUsersCompany,
  verifyUsersCompany,
  changeLevelDiskonUsersCompany,
  changePaylaterLimitUsersCompany,
  addMsDeposits,
  changeIsActiveProfileMsUser,
  changeIsCanPayLaterUsersCompany,
  fetchAllMsCutoffs,
  fetchOneDetailsMsCutoffs,
  changeCutoffDateUsersCompany,
  changeIsPrimaryCompanyAddress,
  changeIsPrimaryCompanyAddressV2,
  generateCutOff,
  generateManualCutOffTOP,
  fetchAllTransPaylater,
  fetchAllPengirimanHd,
  TransDepositRefundV2,
  statusDepositRefund,
  changeIsActiveuserV2
} from '@/data/source/remote/api'

class CompanyUseCase implements ICrud {
  changeIsActive(data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  changeIsActiveUser(id: any, data: any): Promise<Response> {
    return new Repository(changeIsActiveProfileMsUser(id, data), null).getResult(false)
  }

  changeIsActiveV2(id: any, data: any): Promise<Response> {
    return new Repository(changeIsActiveuserV2(id, data), null).getResult(false)
  }

  changeIsPayLater(id: any, data: any): Promise<Response> {
    return new Repository(changeIsCanPayLaterUsersCompany(id, data), null).getResult(false)
  }

  changeIsPrimaryAddress(idUser: number, idAddress: number): Promise<Response> {
    return new Repository(changeIsPrimaryCompanyAddressV2(idUser, idAddress), null).getResult(false)
  }

  generateCutOffTOP(id: any): Promise<Response> {
    // return new Repository(generateCutOff(`?companyId=${id}`), null).getResult(false)
    return new Repository(generateManualCutOffTOP(id), null).getResult(false)
  }

  getAll(filter: any = ''): Promise<Response> {
    let params = '?$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }
      const arrayFilter = []
      if (filter.search) {
        arrayFilter.push(`(Contains(tolower(Username),'${filter.search}'))`)
      }
      if (filter.custom) {
        arrayFilter.push(filter.custom)
      }
      if (filter.filter) {
        arrayFilter.push(filter.filter)
      }
      if (arrayFilter.length > 0) {
        params = `${params}&$filter=${arrayFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.sorting) ? `${params}&$orderBy=${filter.sorting}` : params
    }
    return new Repository(fetchAllUsersCompany(params), null).getResult(false)
  }

  async getDataForm(id: any): Promise<any> {
    throw new Error('Method not implemented.')
  }

  submitData(id: any, data: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  deleteData(id: any): Promise<Response> {
    throw new Error('Method not implemented.')
  }

  getMinimalInfo(filter: any = ''): Promise<Response> {
    console.log('filter', filter)
    return new Repository(fetchMinimalInfoUsersCompany(filter), null).getResult(false)
  }

  getMinimalInfoUnverifiedUsersCompany(filter: any = ''): Promise<Response> {
    return new Repository(fetchMinimalInfoUnverifiedUsersCompany(filter), null).getResult(false)
  }

  getProfile(id: number): Promise<Response> {
    const Id = Number(id)
    return new Repository(fetchProfileUsersCompany(Id), null).getResult(false)
  }

  submitVerify(id: any, type: any): Promise<Response> {
    const Id = Number(id)
    return new Repository(verifyUsersCompany(Id, type), null).getResult(false)
  }

  submitLevelDiscount(id: any, data: any): Promise<Response> {
    const Id = Number(id)
    return new Repository(changeLevelDiskonUsersCompany(Id, data), null).getResult(false)
  }

  submitPaylaterLimit(id: any, data: any): Promise<Response> {
    const Id = Number(id)
    return new Repository(changePaylaterLimitUsersCompany(Id, data), null).getResult(false)
  }

  submitPayTop(data: any): Promise<Response> {
    return new Repository(addMsDeposits(data), null).getResult(false)
  }

  submitCutoffDate(id: any, data: any): Promise<Response> {
    const Id = Number(id)
    return new Repository(changeCutoffDateUsersCompany(Id, data), null).getResult(false)
  }

  getCutOff(filter: any = ''): Promise<Response> {
    // let params = '?$count=true'
    let params = '?'
    // console.log('filter', filter)
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }
      const arrayFilter = []
      if (filter.search) {
        arrayFilter.push(`code=${filter.search.toLowerCase()}`)
      }
      if (filter.custom) {
        arrayFilter.push(filter.custom)
      }
      if (filter.filter) {
        arrayFilter.push(filter.filter)
      }
      if (arrayFilter.length > 0) {
        // params = `${params}&$filter=${arrayFilter.join(' and ')}`
        params = `${params}${arrayFilter.join('&')}`
      }
      // params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      // params = (filter.sorting) ? `${params}&$orderBy=${filter.sorting}` : params
    }
    return new Repository(fetchAllMsCutoffs(params), null).getResult(false)
  }

  getDetailCutoff(id: any): Promise<Response> {
    const Id = Number(id)
    return new Repository(fetchOneDetailsMsCutoffs(Id), null).getResult(false)
  }

  generateCutOff(id: any): Promise<Response> {
    return new Repository(changeIsPrimaryCompanyAddress(id), null).getResult(false)
  }

  getTransPaylater(id: any = ''): Promise<Response> {
    const params = `?$filter=CutoffId eq ${id} AND Status in ("settlement", "unpaid")`
    return new Repository(fetchAllTransPaylater(params), null).getResult(false)
  }

  getDetailCutOffDelivery(ids: any = ''): Promise<Response> {
    let params = '?'
    const select = '$select=Id,CreatedAt,Resi,NamaPengirim,HpPengirim,NamaPenerima,HpPenerima,TotalColly,TotalBerat,KategoriBarang,KeteranganPenerima,GrandTotal'
    const filter = `$filter=Id in (${ids})`
    const expand = '$expand=AgenAsal($select=Id,Nama;$expand=Kota($select=Id,Nama)), AgenTujuan($select=Id,Nama;$expand=Kota($select=Id,Nama)), StatusPengiriman($select=Id,Status,Kode)'
    params = `${params}${select}&${expand}&${filter}`
    // console.log('params', params)

    return new Repository(fetchAllPengirimanHd(params), null).getResult(false)
  }

  submitWithdrawDeposit(companyId: number): Promise<Response> {
    const payload = {
      CompanyId: companyId
    }
    return new Repository(new TransDepositRefundV2().addRefund(payload), null).getResult(false)
  }

  changeStatusTransDeposit(id: number, action: statusDepositRefund): Promise<Response> {
    return new Repository(new TransDepositRefundV2().editStatus(id, action), null).getResult(false)
  }
}

const companyUseCase = new CompanyUseCase()

export {
  companyUseCase
}
