import {
  ref, onMounted, getCurrentInstance, reactive, computed
} from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { receiveItemUseCase } from '@/domain/usecase'
import moment from 'moment'
import {
  DxDataGrid,
  DxColumn,
  DxGrouping,
  DxGroupPanel,
  DxSearchPanel,
  DxPaging,
  DxScrolling,
  DxColumnChooser,
  DxHeaderFilter,
  DxFilterRow,
  DxExport,
  DxPager
} from 'devextreme-vue/data-grid'
import { DxLoadPanel } from 'devextreme-vue/load-panel'
import { downloadExcel } from '@/plugins/report/downloadExcel'

export default {
  emits: ['submitDropAgent', 'submitHandoverItem', 'showDetail', 'showRefund', 'showPending'],
  components: {
    // DxCheckBox,
    DxColumn,
    DxGroupPanel,
    DxGrouping,
    DxPaging,
    DxSearchPanel,
    DxDataGrid,
    DxScrolling,
    DxColumnChooser,
    DxHeaderFilter,
    DxFilterRow,
    DxExport,
    DxLoadPanel,
    DxPager
  },
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const {
      $toast, $numbers, $strInd
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const toAgentId = ref(store.getters['appActiveUser/getAgent'].id)
    const loading = ref(false)
    const noDataText = ref('Tidak Ada Data')
    const router = useRouter()
    const focusedRowKey = ref(17)
    const showHeaderFilter = ref(true)
    const showFilterRow = ref(true)
    const op = ref()
    const autoExpandAll = ref(true)
    const selectedReceipt = ref(null)
    const position = ref({ of: '#delivery-item-table-id' })
    const displayMode = ref('full')
    const pageSizes = ref([5, 10, 15, 20, 25, 50, 100]) as any
    const showPageSizeSelector = ref(true)
    const showInfo = ref(true)
    const showNavButtons = ref(true)
    const data = ref([])
    const inputSearch = ref(null)
    const sendInputSearch = ref('')
    const refTableDeliveryItem = ref()
    const dateOp = ref()
    const dateFilter = ref(null) as any
    const dateFilterTemporary = ref(null) as any
    const indexTab = ref(0) as any

    const filters = reactive({
      // top: $numbers.totalItemPerPage,
      skip: 0,
      totalRecords: 0,
      search: sendInputSearch.value,
      // filter: 'TransJadwalHd ne null',
      select: 'Id,Resi,StatusPengirimanId,CreatedAt,UpdatedAt,TotalColly,HpPenerima,HpPengirim,NamaPengirim,NamaPenerima',
      expand: 'StatusPengiriman($select=Status,Keterangan,Attribute1,Attribute2),TransJadwalHd($select=KodeJadwal,Id,TanggalAktif,Rit),AgenAsal($select=Nama),AgenTujuan($select=Nama),TransPengirimanDetails($select=Id;$expand=KategoriBarang($select=Id,IsRefund))'
    }) as any

    const showDropDown = (evt: any, item: any) => {
      selectedReceipt.value = item
      op.value.toggle(evt)
    }

    const redirectDetailManifest = (item: any) => {
      router.push({ name: 'transaksi-manifest-pengiriman-detail', params: { id: item.TransJadwalHd.Id } })
    }

    const filterTanggal = () => {
      if (dateFilter.value !== null) {
        dateFilterTemporary.value = dateFilter.value
        if (!Array.isArray(dateFilterTemporary.value)) {
          dateFilterTemporary.value = [moment(), moment()]
        }
        return `CreatedAt gt ${moment(dateFilterTemporary.value[0]).format('YYYY-MM-DD')}T00:00:00Z and CreatedAt lt ${moment(dateFilterTemporary.value[1]).format('YYYY-MM-DD')}T23:59:59Z and`
      }
      return `CreatedAt gt ${moment().subtract(3, 'days').format('YYYY-MM-DD')}T00:00:00Z and CreatedAt lt ${moment().format('YYYY-MM-DD')}T23:59:59Z and`
    }

    const getAllData = async () => {
      loading.value = true
      filters.search = sendInputSearch.value
      filters.filter = `${filterTanggal()} AgenAsalId eq ${toAgentId.value} and StatusPengirimanId in (1,4,5,7)`
      const response = await receiveItemUseCase.getAll(filters)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
      } else {
        console.log(response.result)
        /* eslint-disable array-callback-return */
        data.value = response.result.map((x: any) => {
          // x.TotalKoli = x['TransPengirimanDetails@odata.count']
          x.Jadwal = x.TransJadwalHd ? x.TransJadwalHd.KodeJadwal : '-'
          x.NamaAgenTujuan = x.AgenTujuan ? x.AgenTujuan.Nama : '-'
          x.NamaAgenAsal = x.AgenAsal ? x.AgenAsal.Nama : '-'
          x.NamaStatusPengiriman = x.StatusPengiriman ? x.StatusPengiriman.Status : '-'
          x.TanggalJadwalAktif = x.TransJadwalHd ? x.TransJadwalHd.TanggalAktif : '-'
          x.Rit = x.TransJadwalHd ? x.TransJadwalHd.Rit : '-'
          return x
        })
        filters.totalRecords = response.count
      }
      loading.value = false
    }

    const onPage = (event: any) => {
      filters.skip = event.page * filters.top
      getAllData()
    }

    const refund = (item: any) => {
      emit('showRefund', item.Id)
    }

    const exportGrids = (e: any) => {
      try {
        downloadExcel.exportGridExcel(refTableDeliveryItem.value, 'Paket Dikirim')
      } catch {
        $toast.add({
          severity: 'error', detail: $strInd.errorDownload, group: 'bc', life: 1500
        })
      }
    }

    const pending = (item: any) => {
      emit('showPending', item.Id)
    }
    const onFocusedRowChanged = (val: any) => {
      console.log('onFocusedChanged', val)
    }
    const onFocusedRowChanging = (val: any) => {
      console.log('onFocusedChanging', val)
    }

    const showDateFilter = (evt: any) => {
      dateOp.value.toggle(evt)
    }

    const saveFilterDate = (evt: any) => {
      dateOp.value.toggle(evt)
      // filters.tanggalAktif = moment(dateFilter.value).format('YYYY-MM-DD')
      getAllData()
    }

    const clearFilterDate = (evt: any) => {
      dateFilter.value = null
      dateOp.value.hide()
      getAllData()
    }

    onMounted(() => {
      getAllData()
    })
    const searchData = async (val: any) => {
      sendInputSearch.value = await val.toLowerCase()
      getAllData()
    }

    return {
      op,
      data,
      filters,
      loading,
      selectedReceipt,
      moment,
      redirectDetailManifest,
      refund,
      pending,
      showDropDown,
      onPage,
      getAllData,
      inputSearch,
      sendInputSearch,
      searchData,
      onFocusedRowChanging,
      onFocusedRowChanged,
      autoExpandAll,
      focusedRowKey,
      noDataText,
      showHeaderFilter,
      showFilterRow,
      exportGrids,
      refTableDeliveryItem,
      position,
      showNavButtons,
      showInfo,
      showPageSizeSelector,
      pageSizes,
      displayMode,
      showDateFilter,
      dateOp,
      dateFilter,
      saveFilterDate,
      clearFilterDate
    }
  }
}
