import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'
// import { TPayloadRoute } from './routes-model'

const baseUrl = '/backend/v2/schedules'

const fetchAllSchedule = (filter: '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchAllDeliverySchedule = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}/usedForDelivery${filter}`)
const fetchAllAvailableDeliverySchedule = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}/availableForDelivery${filter}`)

export {
  fetchAllSchedule,
  fetchAllDeliverySchedule,
  fetchAllAvailableDeliverySchedule
}