import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'
// import { TPayloadRoute } from './routes-model'

const baseUrl = '/backend/v2/manifests'

const fetchLastManifest = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const addListManifest = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)

export {
  fetchLastManifest,
  addListManifest
}