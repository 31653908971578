import {
  ref, onMounted, getCurrentInstance, reactive, watch
} from 'vue'
import { useStore } from 'vuex'
/* eslint-disable-next-line import/named */
// import { transScheduleBusUseCase } from '@/domain/usecase'
import { checkingResiUseCase } from '@/domain/usecase'
import ItemReceiptCheckingCard from '@/views/home/receipt-checking/ItemReceiptCheckingCard.vue'
import ItemReceiptCheckedCard from '@/views/home/receipt-checking/ItemReceiptCheckedCard.vue'
import DialogSearchChekResi from '@/views/shared-components/dialog/DialogSearchChekResi.vue'
import moment from 'moment'
import { useRoute, useRouter } from 'vue-router'

export default {
  components: {
    ItemReceiptCheckingCard,
    ItemReceiptCheckedCard,
    DialogSearchChekResi
  },
  setup() {
    const app = getCurrentInstance()
    const route = useRoute()
    const router = useRouter()
    const {
      $numbers, $toast, $strInd, $confirm
    } = app!.appContext.config.globalProperties
    const inputSearch = ref('')
    const showDialogDetail = ref(false)
    const idDetail = ref(null) as any
    const data = ref([])
    const receiptChecked = ref([])
    const store = useStore()
    const inputScanResi = ref(null) as any
    const tabsHeader = ref([{
      key: 0,
      title: 'Pending'
    },
    {
      key: 1,
      title: 'Selesai Diproses'
    }
    ])
    const indexTab = ref(0) as any
    const ritOption = [
      // { name: 'AKAP', value: '3' },
      // { name: 'AKDP', value: '4' },
      { name: 'RIT 1', value: '1' },
      { name: 'RIT 2', value: '2' }
    ]
    const sortingOption = ref([
      {
        value: 'TanggalAktif desc',
        name: 'Tanggal Aktif'
      },
      {
        value: 'UpdatedAt desc',
        name: 'Terakhir Update'
      },
      // {
      //   value: 'Jadwal desc',
      //   name: 'Jadwal'
      // },
      // {
      //   value: 'Total PendapatanAgen desc',
      //   name: 'Total Pendapatan Agent'
      // }
    ])
    const sortBy = ref('Id desc')
    const sortOp = ref()
    const dateOp = ref()
    const ritFilter = ref('')
    const cmpSearch = ref()
    const dateFilter = ref(moment().format('YYYY-MM-DD')) as any
    const dateFilterTemporary = ref(null) as any
    const filters = reactive({
      // skip: 0,
      // top: $numbers.totalItemPerPage,
      totalRecords: 0,
      search: inputSearch.value,
      tanggalAktif: dateFilter.value,
      rit: ritFilter.value,
      orderBy: sortBy.value,
      isClose: false,
      hasTransPengirimanHds: true,
      expand: 'TipeBus($select=Kode,Nama),CloseBy,TransPengirimanHds($select=Id;$expand=AgenAsal($select=Kode), AgenTujuan($select=Kode), Manifest), TransPengirimanHds($count=true)'
    })
    const agentOp = ref()
    const arrivalOp = ref()
    const inputCekResi = ref()
    const listChecked = ref([]) as any
    const isShowMModalSearchResi = ref(false)
    /* eslint-disable consistent-return */
    /* eslint-disable no-useless-return */
    /* eslint no-else-return: ["error", {allowElseIf: true}] */
    const filterRit = () => {
      if (ritFilter.value.length === 1) {
        return `Rit in ('${ritFilter.value[0]}') and`
      } else if (ritFilter.value.length === 2) {
        return `Rit in ('${String(ritFilter.value[0])}','${String(ritFilter.value[1])}') and`
      } return ''
    }

    const filterTanggal = () => {
      if (dateFilter.value !== null && indexTab.value === 1) {
        return `CloseDate eq ${moment(dateFilter.value).format('YYYY-MM-DD')} and `
      } else if (indexTab.value === 0) {
        dateFilterTemporary.value = dateFilter.value
        if (!Array.isArray(dateFilterTemporary.value)) {
          dateFilterTemporary.value = [moment(), moment()]
        }
        return `TanggalAktif ge ${moment(dateFilterTemporary.value[0]).format('YYYY-MM-DD')} and TanggalAktif le ${moment(dateFilterTemporary.value[1]).format('YYYY-MM-DD')} and `
      } return ''
    }
    const getAllData = async () => {
      store.dispatch('showLoading')
      filters.search = inputSearch.value
      filters.isClose = (indexTab.value === 1)

      const response = await checkingResiUseCase.getAll({
        // top: filters.top,
        // skip: filters.skip,
        // search: inputSearch.value,
        // expand: '&$expand=TransJadwalHd',
        // expand: '&$expand=TipeBus($select=Kode,Nama),CloseBy($select=Id,NamaLengkap),TransPengirimanHds($select=Id;$expand=AgenAsal($select=Kode), AgenTujuan($select=Kode), Manifest($select=Id,NoManifest)), TransPengirimanHds($count=true)',
        custom: `${filterTanggal()} IsClose eq ${filters.isClose}`,
        // custom: `${filterTanggal()}${filterRit()} TransPengirimanHds/any(x: x/ManifestId ne null) and IsClose eq ${filters.isClose}`,
        // orderBy: sortBy.value
      })
      if (!response.error) {
        if (indexTab.value === 0) {
          filters.totalRecords = response.count
          data.value = response.result
        } else if (indexTab.value === 1) {
          filters.totalRecords = response.count
          receiptChecked.value = response.result
        }
      } else {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    const searchData = (search: any) => {
      getAllData()
    }
    const saveFilterDate = (evt: any) => {
      dateOp.value.toggle(evt)
      filters.tanggalAktif = moment(dateFilter.value).format('YYYY-MM-DD')
      getAllData()
    }
    const showSortFilter = (evt: any) => {
      // console.log('showSortFilter', evt)
      sortOp.value.toggle(evt)
    }
    const showDateFilter = (evt: any) => {
      dateOp.value.toggle(evt)
    }

    const clearFilterDate = (evt: any) => {
      dateFilter.value = null
      filters.tanggalAktif = null
      dateOp.value.hide()
      // arrivalOp.value.toggle(evt)
      getAllData()
    }

    const onPage = (event: any) => {
      // filters.skip = event.page * filters.top
      getAllData()
    }

    const changeSortBy = (evt: any) => {
      // console.log('changeSortBy', evt)
      filters.orderBy = sortBy.value
      getAllData()
    }

    const hideDialogDetail = async () => {
      showDialogDetail.value = false
      // await router.push({
      //   name: 'receipt-checking'
      // })
      inputScanResi.value = null
      inputCekResi.value.$el.focus()
      getAllData()
    }

    watch([ritFilter], () => {
      if (ritFilter.value) {
        filters.rit = ritFilter.value
        getAllData()
      }
    })
    watch(indexTab, (newVal: any) => {
      // console.log('newVal', newVal)
      if (indexTab) {
        if (newVal === 0) {
          dateFilterTemporary.value = [moment(), moment()]
          // dateFilter.value = [moment(), moment()]
        } else if (newVal === 1) {
          dateFilter.value = moment().format('YYYY-MM-DD')
        }
        inputSearch.value = ''
        dateOp.value.hide()
        // cmpSearch.value.reset()
        getAllData()
      }
    })
    const getSearchResiData = (val: any) => {
      console.log('getSearchResiData', val)
      // onChangeToAgentId(val.Id)
    }
    const actDownload = (val: any) => {
      console.log('actDownload', val)
      // onChangeToAgentId(val.Id)
    }
    const openModalDetail = (val: any) => {
      idDetail.value = val
      showDialogDetail.value = true
    }
    const searchResi = async (val: any) => {
      if (val.length === 36) {
        openModalDetail(val)
        // await router.push({ name: 'receipt-checking-params', params: { id: val } })
      }
    }

    onMounted(() => {
      // inputCekResi.value.$el.focus()
      if (route.params.id) {
        openModalDetail(route.params.id)
      } else {
        getAllData()
      }
    })
    return {
      data,
      receiptChecked,
      moment,
      inputSearch,
      filters,
      agentOp,
      arrivalOp,
      sortOp,
      dateOp,
      listChecked,
      isShowMModalSearchResi,
      getSearchResiData,
      onPage,
      getAllData,
      searchData,
      clearFilterDate,
      saveFilterDate,
      tabsHeader,
      indexTab,
      ritOption,
      ritFilter,
      showSortFilter,
      showDateFilter,
      dateFilter,
      sortingOption,
      sortBy,
      cmpSearch,
      changeSortBy,
      showDialogDetail,
      idDetail,
      hideDialogDetail,
      actDownload,
      searchResi,
      inputScanResi,
      inputCekResi
    }
  }
}