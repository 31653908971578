/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import Repository from '@/data/repository'
import {
  fetchAllStockSticker,
  fetchAllStockLabel,
  cetakResiPengirimanHd,
  ubahPengirimanHd,
  addTransUserPengirimans,
  fetchAgentStickerStocks
} from '@/data/source/remote/api'

class DeliveryReceiptUseCase {
  public getSelectDataLabel(filter: any = null): Promise<Response> {
    let params = '?$count=true'
    if (filter) {
      if (filter.expand) {
        params = `${params}${filter.expand}`
      }
      if (filter.select) {
        params = `${params}${filter.select}`
      }

      const arrFilter = []
      if (filter.filter) {
        arrFilter.push(filter.filter)
      }
      if (filter.search) {
        arrFilter.push(`(Contains(tolower(KodeLabel),'${filter.search.toLowerCase()}'))`)
      }
      if (arrFilter.length > 0) {
        params = `${params}&$filter=${arrFilter.join(' and ')}`
      }
      params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
      params = (filter.sorting) ? `${params}&$orderBy=${filter.sorting}` : params
    }
    return new Repository(fetchAllStockLabel(params), null).getResult(false)
  }

  public submitReceipt(data: any): Promise<Response> {
    return new Repository(cetakResiPengirimanHd(data), null).getResult(false)
  }

  public updatePayment(data: any): Promise<Response> {
    return new Repository(ubahPengirimanHd(data), null).getResult(false)
  }

  public getSelectDataSticker(agentId: any, params: any): Promise<Response> {
    // const params = `?$expand=Sticker&$filter=IsActive eq true and AgenId eq ${agentId}`
    return new Repository(fetchAgentStickerStocks(agentId, params), null).getResult(false)
  }

  public submitTransUserPengirimans(data: any): Promise<Response> {
    return new Repository(addTransUserPengirimans(data), null).getResult(false)
  }
}

const deliveryReceiptUsecase = new DeliveryReceiptUseCase()

export {
  deliveryReceiptUsecase
}
