import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive
} from 'vue'
import {
  historyComissionUseCase
} from '@/domain/usecase'
import moment from 'moment'
import CardComissionHistory from '@/views/master/courier/detail/comission-history/CardComissionHistory.vue'

export default {
  name: 'CourierComissionHistory',
  components: {
    CardComissionHistory
  },
  props: {
    Id: {
      default: () => null
    }
  },
  setup(props: any) {
    const app = getCurrentInstance()
    const {
      $confirm,
      $toast,
      $strInd,
      $numbers
    } = app!.appContext.config.globalProperties
    const data = ref([])
    const totalRecords = ref(0)
    const dataBeratKomisiResi = ref(null)
    const dateFilter: any = ref(null)
    const tempDateFilter: any = ref('')
    const isUsingFilter = ref(false)
    const dateOp = ref()
    const filters = reactive({
      offset: 0,
      top: $numbers.totalItemPerPage,
      date: '',
      skip: 0,
      select: '&$select=Id,KurirTransUserPengirimanId,Nominal,IsPayment,Total,Attribute1,Attribute2,CreatedAt,Keterangan',
      // expand: '&$expand=KurirTransUserPengiriman($expand=KurirMsUser($select=Id),KurirTipePengiriman($select=Id,Keterangan,Kode),KurirMsJenisKendaraan($select=Id,Nama),TransPengirimanHd($select=Resi,Id); $select=Id,KurirMsUserId,TransPengirimanHdId,KurirTipePengirimanId,KurirMsJenisKendaraanId)',
      expand: '&$expand=KurirTransUserPengiriman($expand=KurirMsUser($select=Id),KurirMsJenisKendaraan($select=Id,Nama),TransPengirimanHd($select=Resi,Id); $select=Id,KurirMsUserId,TransPengirimanHdId,KurirTipePengirimanId,KurirMsJenisKendaraanId)',
      custom: `KurirMsUserId eq ${props.Id} ${tempDateFilter.value}`
    })

    /** Function untuk ambil data dri api */
    const getDataComission = () => {
      historyComissionUseCase.getAll(filters).then((result: any) => {
        if (!result.error) {
          totalRecords.value = result.count
          data.value = result.result
        } else {
          $toast.add({
            severity: 'error',
            detail: $strInd.toast.errorAllData,
            group: 'bc',
            life: 3000
          })
        }
      })
    }

    /** Fungsi untuk perpindahan pagination */
    const onPage = (event: any) => {
      filters.skip = event.page * filters.top
      getDataComission()
    }

    const getWeightResiComission = () => {
      historyComissionUseCase.getBeratKomisiResi(props.Id).then((response: any) => {
        // console.log('amamam', response)
        if (!response.error) {
          dataBeratKomisiResi.value = response.result
        }
      })
    }

    const saveFilterDate = async () => {
      // console.log('lkl', dateFilter.value.length)
      const filterDate = dateFilter.value.filter((x: any) => !x)
      if (filterDate.length === 0) {
        isUsingFilter.value = true
        filters.date = await `CreatedAt ge ${moment(dateFilter.value[0]).format('YYYY-MM-DD')} and CreatedAt le ${moment(dateFilter.value[1]).format('YYYY-MM-DD')}`
        await getDataComission()
        await dateOp.value.hide()
      } else {
        $toast.add({
          severity: 'error',
          detail: 'Gunakan range tanggal untuk filter',
          group: 'bc',
          life: 3000
        })
      }
      // if ()
    }

    const clearFilterDate = (evt: any) => {
      dateFilter.value = null
      filters.date = ''
      dateOp.value.hide()
      // arrivalOp.value.toggle(evt)
      getDataComission()
    }

    const showDateFilter = (evt: any) => {
      dateOp.value.toggle(evt)
    }

    onMounted(() => {
      getDataComission()
      getWeightResiComission()
    })
    return {
      data,
      totalRecords,
      onPage,
      filters,
      moment,
      dataBeratKomisiResi,
      dateFilter,
      dateOp,
      saveFilterDate,
      clearFilterDate,
      showDateFilter,
      isUsingFilter
    }
  }
}
