import {
  transScheduleBusUseCase,
  deliveryPendingUseCase
} from '@/domain/usecase'
import {
  onMounted,
  ref,
  getCurrentInstance,
  reactive,
  defineComponent
} from 'vue'
import {
  useStore
} from 'vuex'
import {
  useRoute,
  useRouter
} from 'vue-router'
import TabsDay from '@/views/transactions/delivery-item/create/steps-1/components/TabsDay.vue'
import moment from 'moment'
import { checkPermission } from '@/utils/helpers/checkPermission'
import PermissionEnum from '@/utils/enum/permissions'

export default defineComponent({
  name: 'TableListJadwal',
  components: {
    TabsDay
  },
  props: ['fromAgentId', 'toAgentId', 'noResi'],
  setup(props: any) {
    const propertiesProps = reactive(props)
    const router = useRouter()
    const route = useRoute() as any
    const scheduleId = ref(0)
    const app = getCurrentInstance()
    const {
      $toast,
      $confirm
    } = app!.appContext.config.globalProperties
    const listSchedule = ref([])
    const setIndexTabs = ref('0d')
    const store = useStore()
    const activeStyle = (idSchedule: any) => {
      if (idSchedule === scheduleId.value) {
        return {
          backgroundColor: '#FFF3E0'
        }
      }
      return {}
    }

    const selectedSchedule = (id: any) => {
      if (route.meta.permission.update) {
        scheduleId.value = id
      } else {
        $toast.add({
          severity: 'error',
          detail: 'Mohon maaf, anda tidak memiliki hak akses untuk memilih',
          group: 'bc',
          life: 3000
        })
      }
    }

    const getScheduleBus = async () => {
      store.dispatch('showLoading')
      const response = await transScheduleBusUseCase.searchJadwalBus(`?origAgentId=${propertiesProps.fromAgentId}&destAgentId=${propertiesProps.toAgentId}&departureOffset=${setIndexTabs.value}&pageSize=100&pageNumber=1`)
      if (!response.error) {
        listSchedule.value = response.result.Data
      } else {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
      }
      store.dispatch('hideLoading')
    }

    const changeTabs = (val: any) => {
      const arrHeader = ['0d', '1d', '2d']
      setIndexTabs.value = arrHeader[val]
      getScheduleBus()
    }

    const prosesSubmit = async () => {
      store.dispatch('showLoading')
      const data = {
        PengirimanHdId: Number(route.params.id),
        JadwalHdId: Number(scheduleId.value)
      }
      const response = await deliveryPendingUseCase.changeScheduleDelivery(data)
      if (response.error) {
        store.dispatch('hideLoading')
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
      } else {
        store.dispatch('hideLoading')
        $toast.add({
          severity: 'success',
          detail: response.result.detail ?? response.result.Detail,
          group: 'bc',
          life: 3000
        })
        router.push({
          name: 'transaksi-pengiriman-pending'
        })
      }
    }

    const submitData = () => {
      if (scheduleId.value > 0) {
        $confirm.require({
          message: `Barang dengan nomor resi "${propertiesProps.noResi}" akan dimasukkan kedalam manifest
          pengiriman jadwal baru. Pastikan resi dan jadwal sudah sesuai !`,
          header: 'Ubah Jadwal Barang Pending',
          icon: 'pi pi-exclamation-triangle',
          rejectLabel: 'Kembali',
          acceptLabel: 'Ubah Jadwal',
          accept: () => {
            prosesSubmit()
          }
        })
      } else {
        $toast.add({
          severity: 'error',
          detail: 'Jadwal harus dipilih terlebih dahulu',
          group: 'bc',
          life: 3000
        })
      }
    }

    onMounted(() => {
      getScheduleBus()
    })
    return {
      activeStyle,
      selectedSchedule,
      scheduleId,
      listSchedule,
      changeTabs,
      setIndexTabs,
      moment,
      router,
      route,
      submitData,
      checkPermission,
      PermissionEnum
    }
  }
})
