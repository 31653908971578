/* eslint-disable class-methods-use-this */
import {
  fetchAgen,
  fetchOneAgen,
  deleteAgen,
  addAgen,
  editAgen,
  changeIsActiveAgent,
  fetchAllKategoriBarang,
  qrProfileAgent,
  addKuotaAgen,
  fetchAgentSingle,
  fetchAgentAll,
  fetchAgentDetailByUsername,
  fetchAllAgent,
  addAgent,
  editAgent
} from '@/data/source/remote/api'
import Repository from '@/data/repository'
import { Response } from '@/domain/entities'
import returnUrlPramsOdata from '@/utils/helpers/returnUrlPramsOdata'
import type { TResultAgent, TResultAgentSingle, TResultAgentDetailByUsername } from '@/data/source/remote/api/backend/V2/msAgens-model'
import RepositoryV2 from '@/data/repositoryV2'
import returnUrlPrams, { IFilterPrams } from '@/utils/helpers/returnUrlPrams'

const getListAgent = (filter: any = {}) => {
  let params = '?$count=true'
  if (filter) {
    if (filter.expand) {
      params = `${params}${filter.expand}`
    }
    if (filter.select) {
      params = `${params}${filter.select}`
    }

    const arrayFilter = []
    if (filter.search) {
      arrayFilter.push(`(Contains(tolower(Nama), '${filter.search}') or Contains(tolower(Alamat), '${filter.search}') or Contains(tolower(Kota/Nama), '${filter.search}'))`)
    }
    if (filter.custom) {
      arrayFilter.push(filter.custom)
    }
    if (filter.filter) {
      arrayFilter.push(filter.filter)
    }
    if (arrayFilter.length > 0) {
      params = `${params}&$filter=${arrayFilter.join(' and ')}`
    }
    params = (filter.top) ? `${params}&$top=${filter.top}&$skip=${filter.skip}` : params
    params = (filter.sorting) ? `${params}&$orderBy=${filter.sorting}` : params
  }
  return new Repository(fetchAgen(params), null).getResult(false)
}

const submitNewAgen = async (id: any, data: any) => {
  let response
  if (id) {
    response = await new Repository(editAgent(data.Id, data), null).getResult(false)
  } else {
    response = await new Repository(addAgent(data), null).getResult(false)
  }
  return response
}

const getDetailAgen = (id: string): Promise<Response<TResultAgentSingle>> => new Repository(fetchAgentSingle(id), null).getResult(false)

const dataEditAgen = (id: any, query: any = {}) => new Repository(fetchOneAgen(id, returnUrlPramsOdata(query)), null).getResult(false)

const deleteDataAgen = (id: any) => new Repository(deleteAgen(id), null).getResult(false)

const editIsActiveAgent = (data: any) => new Repository(changeIsActiveAgent(data), null).getResult(false)

const scanQrProfileAgent = () => new Repository(qrProfileAgent(), null).getResult(false)

// eslint-disable-next-line consistent-return
const submitAddKuotaAgent = (id: any, data: any) => {
  if (id) {
    // eslint-disable-next-line no-unused-expressions
    data.idAgen = Number(id)
  }
  return new Repository(addKuotaAgen(id, data), null).getResult(false)
}

class AgentUseCase {
  getAllAgent(filters: Partial<IFilterPrams> = {}): Promise<Response<TResultAgent[]>> {
    const urlParams = returnUrlPrams(filters)
    return new RepositoryV2(fetchAgentAll(urlParams), null).getResult(false)
  }

  getAllAgentV2(filters: Partial<IFilterPrams> = {}): Promise<Response<TResultAgent[]>> {
    const urlParams = returnUrlPrams(filters)
    return new RepositoryV2(fetchAllAgent(urlParams), null).getResult(false)
  }

  getDetailByUsername(username: string): Promise<Response<TResultAgentDetailByUsername>> {
    return new RepositoryV2<TResultAgentDetailByUsername>(fetchAgentDetailByUsername(username), null).getResult(false)
  }
}

const agentUseCase = new AgentUseCase()

export {
  getListAgent,
  submitNewAgen,
  deleteDataAgen,
  dataEditAgen,
  editIsActiveAgent,
  scanQrProfileAgent,
  submitAddKuotaAgent,
  getDetailAgen,
  agentUseCase
}
