/* eslint-disable class-methods-use-this */
import { Response } from '@/domain/entities'
import ResponseGoogle from '@/data/responseGoogle'
import GooglePlaceProvider from 'google-place-provider'
import { fetchPlaceAutocomplete, fetchPlaceDetails } from '@/data/source/remote/api'
// import google from 'google.maps'

class PlaceGoogleUseCase {
  service: any = new GooglePlaceProvider('AIzaSyBeIU_SpMpwm43tEK-y0XvWCZtPFwxFzsQ')

  async getPlaceAutocomplate(search: any): Promise<Response> {
    const service = new google.maps.places.AutocompleteService()
    const response = new Response()
    await service.getQueryPredictions({ input: search }, (predictions: any[] | null,
      status: any) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
        response.error = true
        response.code = 404
        response.message = status
        response.result = []
      }
      response.error = false
      response.code = 200
      response.message = status
      response.result = predictions
    })
    return response
  }

  displaySuggestions(predictions: any[] | null,
    status: any): Response {
    const response = new Response()
    if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
      response.error = true
      response.code = 404
      response.message = status
      response.result = []
      return response
    }
    response.error = false
    response.code = 200
    response.message = status
    response.result = predictions
    return response
  }

  getPlaceDetails(placeId: any): Promise<Response> {
    return new ResponseGoogle(fetchPlaceDetails(placeId), null).getResult(false)
  }
}

const placeGoogleUseCase = new PlaceGoogleUseCase()

export {
  placeGoogleUseCase
}
