import {
  ref, onMounted, getCurrentInstance, watch
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { deliveryManifestUseCase, reportUseCase } from '@/domain/usecase'
import moment from 'moment'
import { forEach } from 'lodash'
import { isMobile } from '@/plugins/system/device'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import { fileWrite } from '@/plugins/system/filesystem'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'

export default {
  name: 'DeliveryManifest',
  setup() {
    const app = getCurrentInstance()
    const { $swal, $toast, $strInd } = app!.appContext.config.globalProperties
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const data = ref([])
    const inputSearch = ref('')
    const sortOp = ref()
    const dateOp = ref()
    const isEmpty = ref(false)
    const lastManifestExist = ref(false)
    const lastManifest = ref([])
    const lastManifestId = ref('')
    const lastNoManifest = ref('')
    const sortingOption = ref([
      {
        value: 'ActiveDate desc',
        name: 'Waktu Keberangkatan'
      },
      {
        value: 'Code desc',
        name: 'Jadwal'
      },
      {
        value: 'Revenue desc',
        name: 'Total Pendapatan Agen'
      }
    ])
    const sortBy = ref('ActiveDate desc')
    const dateFilter = ref(moment().format('YYYY-MM-DD'))
    const ritFilter = ref(null)
    // const filters = {
    //   search: '',
    //   filter: '',
    //   sorting: 'TanggalAktif desc',
    //   date: moment().format('YYYY-MM-DD'),
    //   expand: '',
    //   totalRecords: 0,
    //   top: 0,
    //   skip: 0,
    //   rit: [] as any
    // }
    const ritOption = [
      { name: 'RIT 1', value: 1 },
      { name: 'RIT 2', value: 2 }
    ]

    const filters = {
      pageNumber: 1,
      pageSize: 10,
      totalRecords: 0,
      search: '',
      filterField: ['code'],
      custom: [
        ['activeDate', '=', dateFilter.value],
        ['orderBy', '=', sortBy.value]
      ] as any
    }

    const getAllData = async () => {
      store.dispatch('showLoading')
      data.value = []
      // console.log('filter', returnUrlPrams(filters))
      const response = await deliveryManifestUseCase.getAll(returnUrlPrams(filters))
      if (response.error) {
        isEmpty.value = true
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
      } else {
        // console.log('data', response)
        filters.totalRecords = response.result.Count
        data.value = response.result.Data
        isEmpty.value = data.value.length === 0
      }
      store.dispatch('hideLoading')
    }

    const searchData = (search: any) => {
      filters.search = search
      filters.pageNumber = 1
      // filters.custom = [['orderBy', '=', 'ActiveDate desc']]
      getAllData()
    }

    const showDateFilter = (evt: any) => {
      dateOp.value.toggle(evt)
    }

    const showSortFilter = (evt: any) => {
      sortOp.value.toggle(evt)
    }

    const changeSortBy = async (val: any) => {
      // sortBy.value = await val
      // console.log('orderVal', val)
      // eslint-disable-next-line array-callback-return
      filters.custom.map(async (x: any, index: any) => {
        if (x[0] === 'orderBy') {
          filters.custom[index][2] = await val
          // console.log('filter', filters.custom)
          filters.search = ''
          inputSearch.value = ''
          filters.pageNumber = 1
          getAllData()
        }
      })
    }

    const saveFilterDate = () => {
      // console.log('date', dateFilter.value)
      // filters.date = moment(dateFilter.value).format('YYYY-MM-DD')
      filters.custom.map(async (x: any, index: any) => {
        if (x[0] === 'activeDate') {
          filters.custom[index][2] = await moment(dateFilter.value).format('YYYY-MM-DD')
          // console.log('filter', filters.custom)
          filters.search = ''
          inputSearch.value = ''
          filters.pageNumber = 1
          getAllData()
        }
      })
      // filters.pageNumber = 1
      // getAllData()
      dateOp.value.hide()
    }

    const clearFilterDate = () => {
      dateFilter.value = moment().format('YYYY-MM-DD')
      // filters.date = moment().format('YYYY-MM-DD')
      filters.custom.map(async (x: any, index: any) => {
        if (x[0] === 'activeDate') {
          filters.custom[index][2] = await moment().format('YYYY-MM-DD')
          // console.log('filter', filters.custom)
          filters.search = ''
          filters.pageNumber = 1
          getAllData()
        }
      })
      filters.pageNumber = 1
      getAllData()
      dateOp.value.hide()
    }

    const strDate = (date: any) => {
      const now = new Date() as any
      const dateFormat = new Date(date) as any
      const diff = Math.floor((dateFormat - now) / (1000 * 3600 * 24))
      let result = ''
      if (diff === 1) {
        result = `BESOK, ${moment(date).format('HH:mm')} WIB`
      } else if (diff === 0) {
        result = `HARI INI, ${moment(date).format('HH:mm')} WIB`
      } else if (diff > 0) {
        result = `${moment(date).format('DD-MM-YYYY HH:mm')} WIB`
      } else {
        result = `TERLAMBAT, ${moment(date).format('DD-MM-YYYY HH:mm')} WIB`
      }
      return result
    }

    const classDate = (date: any) => {
      const now = new Date() as any
      const dateFormat = new Date(date) as any
      const diff = Math.floor((dateFormat - now) / (1000 * 3600 * 24))
      if (diff < 0) {
        return 'color-red'
      }
      return 'color-green-darken-3'
    }

    // converter base64
    const toBase64 = (file: any) => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

    const printResi = (val: any) => {
      // console.log('val', val)
      // const encryptData = encryptDecriptMethods.encrypt(val.Id)
      const routeData = router.resolve({ name: 'report', params: { Id: val, jenisReport: 'manifest' } })
      window.open(routeData.href, '_blank')
    }

    const filtersv2 = {
      expand: '&$expand=TransPengirimanHds($select=AgenAsalId)',
      filter: '',
      orderby: '&$orderBy=CreatedAt desc'
    }
    const getLastManifest = async () => {
      // const AgenAsalId = store.getters['appActiveUser/getAgent']?.id
      // filtersv2.filter = `&$filter=TransPengirimanHds/any(o:o/AgenAsalId eq ${AgenAsalId})`
      const response = await deliveryManifestUseCase.getLastManifest()
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
      } else {
        console.log(response.result)
        if (response.result.Data.length > 0) {
          // eslint-disable-next-line no-multi-assign
          lastManifest.value = response.result.Data[0]?.Id
          const encryptData = encryptDecriptMethods.encrypt(response.result.Data[0]?.Id)
          lastManifestId.value = encryptData
          // console.log(lastManifestId)
          lastNoManifest.value = response.result.Data[0]?.Code
          lastManifestExist.value = true
        }
      }
      store.dispatch('hideLoading')
    }

    const onPage = (event: any) => {
      filters.pageNumber = event.page + 1
      getAllData()
    }

    onMounted(() => {
      getAllData()
      getLastManifest()
      // console.log('store', store)
    })

    watch([ritFilter], () => {
      // console.log('xxx')
      if (ritFilter.value) {
        filters.custom.map(async (x: any, index: any) => {
          if (x[0] === 'rit') {
            filters.custom[index][2] = ritFilter.value
          } else {
            filters.custom.push(['rit', '=', ritFilter.value])
          }
        })
        filters.search = ''
        inputSearch.value = ''
        filters.pageNumber = 1
        getAllData()
      }
    })

    return {
      data,
      inputSearch,
      store,
      route,
      sortOp,
      dateOp,
      sortingOption,
      sortBy,
      dateFilter,
      ritOption,
      ritFilter,
      filters,
      changeSortBy,
      strDate,
      showDateFilter,
      showSortFilter,
      classDate,
      searchData,
      getAllData,
      saveFilterDate,
      clearFilterDate,
      moment,
      printResi,
      lastManifest,
      lastManifestExist,
      lastManifestId,
      lastNoManifest,
      onPage,
      isEmpty
    }
  }
}
