/* eslint-disable import/no-unresolved, class-methods-use-this, import/no-cycle */
import {
  loginApi, fetchOneGlobalSetting,
  sendLogin, sendConsent, sendCallback, sendLogout, fetchSingleAgentById, fetchUserInfo, fetchAgentInfo,
  fetchProfileV2
} from '@/data/source/remote/api'
import { LocalDb, localDbEnum, LocalDbEntity } from '@/data/source/local/databases/localDb'
import Repository from '@/data/repository'
import { Response } from '@/domain/entities'
import { environment } from '@/utils'
import { IResultFetchUserInfo } from '@/data/source/remote/api/auth/userinfo-model'
import { IResultSendCallback } from '@/data/source/remote/api/auth/oauth-model'
import { IResultAgentInfo } from '@/data/source/remote/api/backend/V2/dashboard-model'
import { TResultProfile } from '@/data/source/remote/api/identity/V2/profile-model'

class AuthUseCase {
  loginFlow(username: string, password: string) {
    const data = new FormData()
    data.append('Username', username)
    data.append('Password', password)

    return new Repository(loginApi(), new LocalDb(new LocalDbEntity(localDbEnum.noAction))).getResult(false)
  }

  sendingLoginChallenge(valLoginChallenge: any, username: string, password: string) {
    const data = {
      /* eslint-disable object-shorthand */
      username: username,
      password: password,
      remember: false
    }
    return new Repository(sendLogin(valLoginChallenge, data), new LocalDb(new LocalDbEntity(localDbEnum.noAction))).getResult(false)
  }

  sendingConsentChallenge(valChallenge: any) {
    const data = {
      /* eslint-disable object-shorthand */
      remember: true
    }
    return new Repository(sendConsent(valChallenge, data), new LocalDb(new LocalDbEntity(localDbEnum.noAction))).getResult(false)
  }

  sendingAuth({ code, scope, state }: {
    code: string,
    scope: string,
    state: string
  }): Promise<Response<IResultSendCallback>> {
    const data = new URLSearchParams()
    data.append('redirect_uri', `${window.location.origin}/callback`)
    data.append('grant_type', environment.getHydraGrantType())
    data.append('client_id', environment.getHydraClientId())
    data.append('client_secret', environment.getHydraClientSecret())
    data.append('code', code)
    data.append('scope', scope)
    data.append('state', state)
    return new Repository<IResultSendCallback>(sendCallback(data), new LocalDb(new LocalDbEntity(localDbEnum.noAction))).getResult(false)
  }

  sendingLogOutAccount(data: any) { return new Repository(sendLogout(data), null).getResult(false) }

  getGlobalSetting(Id: any) { return new Repository(fetchOneGlobalSetting(Id), null).getResult(false) }

  getLoginUser(id: string) { return new Repository(fetchSingleAgentById(id), null).getResult(false) }

  getUserInfo(): Promise<Response<IResultFetchUserInfo>> {
    return new Repository<IResultFetchUserInfo>(fetchUserInfo(), null).getResult(false)
  }

  getAgentInfo(): Promise<Response<IResultAgentInfo>> {
    return new Repository<IResultAgentInfo>(fetchAgentInfo(), null).getResult(false)
  }

  getProfile(): Promise<Response<TResultProfile>> {
    return new Repository<TResultProfile>(fetchProfileV2(), null).getResult(false)
  }
}

const authUseCase = new AuthUseCase()

export {
  authUseCase
}
