import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive
} from 'vue'
import {
  useStore
} from 'vuex'
import {
  useRouter,
  useRoute
} from 'vue-router'
import {
  dataEditAgen,
  submitNewAgen,
  getSelectKota,
  agentTypeUseCase,
  deleteDataAgen
} from '@/domain/usecase'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers,
  numeric,
  minLength,
  maxLength
} from '@vuelidate/validators'

export default {
  setup() {
    const tipeAgentOption = ref([]) as any
    const kotaOption = ref([])
    const Id = ref()
    const codeAgent = ref(null)
    const address = ref(null)
    const receiptCode = ref(null)
    const isActive = ref(false)
    const nameAgent = ref(null)
    const phoneAgent = ref(null)
    const kotaId = ref(null)
    const tipeAgentId = ref(null)
    const komisiAA = ref(Number)
    const komisiAD = ref(Number)
    const komisiDA = ref(Number)
    const komisiDD = ref(Number)
    const CanPickup = ref(false)
    const CanDrop = ref(false)
    const IsLuarPulau = ref(false)
    const IsKurirDooring = ref(false)
    const openMap = ref(false)
    const router = useRouter()
    const route = useRoute()
    const app = getCurrentInstance()
    const addressRef = ref()
    const store = useStore()
    const dataForm = reactive({
      codeAgent: null,
      address: null,
      Latitude: null,
      Longitude: null,
      receiptCode: null,
      isActive: true,
      nameAgent: null,
      phoneAgent: null,
      kotaId: null,
      tipeAgentId: null,
      komisiAA: 0,
      komisiAD: 0,
      komisiDA: 0,
      komisiDD: 0,
      CanPickup: false,
      CanDrop: false,
      IsLuarPulau: false,
      IsKurirDooring: false,
      RIT1: false,
      RIT2: false,
      workingHour: 0
    })
    const submitted = ref(false)
    const mapData = ref({
      selectedLocation: '',
      lat: '',
      lng: ''
    }) as any
    const rules = {
      codeAgent: {
        required: helpers.withMessage('Kode Agen harus diisi', required)
      },
      address: {
        required: helpers.withMessage('Alamat Lengkap harus diisi', required)
      },
      receiptCode: {
        required: helpers.withMessage('Kode Resi harus diisi', required)
      },
      kotaId: {
        required: helpers.withMessage('Kota harus diisi', required)
      },
      nameAgent: {
        required: helpers.withMessage('Nama Agen harus diisi', required)
      },
      phoneAgent: {
        required: helpers.withMessage('Nomor whatsapp harus diisi', required),
        numeric: helpers.withMessage('Nomor whatsapp harus berisi angka', numeric),
        minLength: helpers.withMessage('Nomor whatsapp harus berisi minimal 11 digit', minLength(11)),
        maxLength: helpers.withMessage('Nomor whatsapp harus berisi maksimal 13 digit', maxLength(13)),
      },
      tipeAgentId: {
        required: helpers.withMessage('Tipe Agen harus diisi', required)
      },
      workingHour: {
        required: helpers.withMessage('Jam Kerja Kurir harus diisi', required)
      }
    }
    const v$ = useVuelidate(rules, dataForm)

    const redirectBack = () => {
      router.go(-1)
    }
    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const checkResponse = async (response: any) => {
      console.log('response after', response)
      if (response.error) {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
      } else {
        if (Id.value) {
          $toast.add({
            severity: 'success',
            // summary: response.result.title,
            // detail: response.result.detail ?? response.result.Detail,
            detail: $strInd.toast.successSubmit,
            group: 'bc',
            life: 1500
          })
        } else {
          $toast.add({
            severity: 'success',
            detail: $strInd.toast.successSubmit,
            group: 'bc',
            life: 1500
          })
        }
        redirectBack()
      }
    }
    const submitProccess = async () => {
      const data = {
        Id: Id.value ? Number(Id.value) : 0,
        CityId: dataForm.kotaId,
        Code: dataForm.codeAgent,
        Name: dataForm.nameAgent,
        Address: dataForm.address,
        Longitude: dataForm.Longitude,
        Latitude: dataForm.Latitude,
        AaCommission: Number(dataForm.komisiAA),
        DdCommission: Number(dataForm.komisiAD),
        AdCommission: Number(dataForm.komisiAD),
        DaCommission: Number(dataForm.komisiDA),
        CanPickup: dataForm.CanPickup,
        CanDrop: dataForm.CanDrop,
        AgentTypeId: dataForm.tipeAgentId,
        IsOutOfJavaIsland: dataForm.IsLuarPulau,
        HasCourier: dataForm.IsKurirDooring,
        WaybillPrefix: dataForm.receiptCode,
        IsActive: dataForm.isActive,
        Rit1: dataForm.RIT1,
        Rit2: dataForm.RIT2,
        CourierBusinessHours: Number(dataForm.workingHour),
        PhoneNumber: dataForm.phoneAgent
      }
      // }
      const response = await submitNewAgen(Id.value, data)
      checkResponse(response)
    }
    const submitData = (isFormValid: boolean) => {
      // console.log(isFormValid)
      submitted.value = true
      if (!isFormValid) {
        return
      }
      submitProccess()
    }
    // eslint-disable-next-line
    const getDecriptId = () => {
      // console.log('decript data', route.params)
      return route.params.agentId
    }
    const getOptionCity = async () => {
      const filterController = '$select=Id,IsActive,Provinsi,Nama,PulauId,Kode&$filter=IsActive eq true'
      const response = await getSelectKota(filterController)
      // console.log('response ', response)
      kotaOption.value = response.result.map((x: any) => ({
        value: x.Id,
        label: x.Nama
      }))
    }
    const getOptionTipeAgen = async () => {
      const filterController = {
        search: '',
        filterField: [],
        pageSize: 1000,
        pageNumber: 1,
        isSearcLowerCase: true,
        custom: [['IsActive', '=', 'true'], ['IsDeleted', '=', 'false']]
      }
      const response = await agentTypeUseCase.getAll(filterController)
      // console.log('response ', response)
      tipeAgentOption.value = response.result.map((x: any) => ({
        value: x.Id,
        label: `${x.Tipe} - ${x.Nama}`
      }))
    }
    const deleteData = () => {
      $confirm.require({
        header: 'Agen',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const response = await deleteDataAgen(Id.value)
          if (response.error) {
            $toast.add({
              severity: 'error',
              detail: $strInd.toast.errorDelete,
              group: 'bc',
              life: 3000
            })
          } else {
            $toast.add({
              severity: 'success',
              detail: $strInd.toast.successDeleteData,
              group: 'bc',
              life: 3000
            })
            redirectBack()
          }
        }
      })
    }
    const getDataEdit = async () => {
      store.dispatch('showLoading')
      const result = await dataEditAgen(Id.value)
      // console.log('result data edit', result)
      dataForm.codeAgent = result.result.Kode
      dataForm.address = result.result.Alamat
      dataForm.Latitude = result.result.Latitude
      dataForm.Longitude = result.result.Longitude
      dataForm.receiptCode = result.result.KodeResi
      dataForm.isActive = result.result.IsActive
      dataForm.nameAgent = result.result.Nama
      dataForm.kotaId = result.result.KotaId
      dataForm.komisiAA = result.result.KomisiAa
      dataForm.komisiAD = result.result.KomisiAd
      dataForm.komisiDA = result.result.KomisiDa
      dataForm.komisiDD = result.result.KomisiDd
      dataForm.CanDrop = result.result.CanDrop
      dataForm.CanPickup = result.result.CanPickup
      dataForm.IsKurirDooring = result.result.IsKurirDooring
      dataForm.IsLuarPulau = result.result.IsLuarPulau
      dataForm.tipeAgentId = result.result.TipeAgenId
      dataForm.RIT1 = result.result.Rit1
      dataForm.RIT2 = result.result.Rit2
      dataForm.workingHour = result.result.JamKerjaKurir
      dataForm.phoneAgent = result.result.Hp
      store.dispatch('hideLoading')
    }
    const setResultAddress = (result: any) => {
      dataForm.address = result
    }
    const receiveMapData = (result: any) => {
      openMap.value = false
      mapData.value = JSON.parse(result)
      // console.log(mapData.value)
      dataForm.address = mapData.value?.selectedLocation
      dataForm.Latitude = mapData.value?.lat
      dataForm.Longitude = mapData.value?.lng
    }
    getDecriptId()
    onMounted(() => {
      Id.value = getDecriptId()
      // console.log('data id', Id)
      if (Id.value) {
        getDataEdit()
      }
      getOptionCity()
      getOptionTipeAgen()
    })
    // const openWindowMap = () => {
    //   openMap
    // }
    return {
      Id,
      codeAgent,
      tipeAgentOption,
      address,
      receiptCode,
      isActive,
      nameAgent,
      phoneAgent,
      kotaId,
      kotaOption,
      komisiAA,
      komisiAD,
      komisiDA,
      komisiDD,
      CanDrop,
      CanPickup,
      IsKurirDooring,
      IsLuarPulau,
      redirectBack,
      tipeAgentId,
      router,
      app,
      submitProccess,
      getDecriptId,
      store,
      checkResponse,
      getOptionCity,
      // getOptionTipeAgen,
      route,
      getDataEdit,
      deleteData,
      setResultAddress,
      addressRef,
      dataForm,
      v$,
      submitted,
      rules,
      submitData,
      openMap,
      receiveMapData,
      mapData
      // openWindowMap
    }
  }
}
