import {
  ref,
  onMounted,
  getCurrentInstance,
  defineComponent
} from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { deliveryManifestUseCase, reportUseCase } from '@/domain/usecase'
import { fileWrite } from '@/plugins/system/filesystem'
import { isMobile } from '@/plugins/system/device'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import { checkPermission } from '@/utils/helpers/checkPermission'
import PermissionEnum from '@/utils/enum/permissions'

/* eslint-disable */
export default defineComponent({
  setup() {
    const data = ref([])
    const app = getCurrentInstance()
    const { $swal, $toast } = app!.appContext.config.globalProperties
    const idManifest = ref(null)
    const showDialogDetail = ref(false)
    const isRefund = ref(false)
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const canPrint = ref(checkPermission(PermissionEnum.CanPrint))

    const getAllData = async () => {
      store.dispatch('showLoading')
      const check = checkPermission(PermissionEnum.ShowDetLoadMan)
      const response = await deliveryManifestUseCase.getLoadingManifest(route.params.id, store.getters['appActiveUser/getAgent']?.id, check)
      if (!response.error) {
        data.value = response.result.map((item: any) => {
          item.resi = []
          item.loading = false
          return item
        })
        // console.log('response', data.value)
      }
      store.dispatch('hideLoading')
    }

    const getManifestDetail = async (item: any) => {
      const response = await deliveryManifestUseCase.getLoadingManifestDetail(route.params.id, item.AgenId)
      if (response.error) {
        item.resi = []
      } else {
        item.resi = response.result
      }
      item.loading = false
      // console.log('getManifestDetail', response)
    }

    const expandedAccordion = (item: any) => {
      const dataItem = data.value[item.index] as any
      if (dataItem.resi.length === 0 && dataItem.TotalResi > 0) {
        dataItem.loading = true
        getManifestDetail(dataItem)
      }
      // console.log('item', data.value[item.index], item)
    }

    const hideDialogDetail = () => {
      showDialogDetail.value = false
    }

    const detail = (val: any) => {
      idManifest.value = val
      showDialogDetail.value = true
    }

    // converter base64
    const toBase64 = (file: any) => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

    const printResi = (val: any) => {
      // console.log('val click', val)
      $swal.fire({
        title: 'Pilih opsi ?',
        // text: 'You won be able to revert this!',
        // icon: 'question',
        imageUrl: require('@/assets/img/icon/printer.svg'),
        imageWidth: 400,
        imageHeight: 150,
        imageAlt: 'Custom image',
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonColor: '#ff9800',
        cancelButtonColor: '#F44336',
        denyButtonColor: '#90A4AE',
        allowOutsideClick: false,
        confirmButtonText: 'Download Manifest',
        cancelButtonText: 'Kembali',
        denyButtonText: 'Tampilkan Manifest'
      }).then(async (result: any) => {
        // console.log('resultcompo', result)
        if (result.isConfirmed) {
          // eslint-disable-next-line no-shadow
          const result = await reportUseCase.getPdfManifest(Number(val.ManifestId))
          const name = `${val.Manifest.Code}.pdf`
          let notif = null

          if (await isMobile()) {
            const file = await toBase64(new Blob([result.result]))
            await fileWrite(name, file)
            // console.log('loadingManifestComposable download via android')
            notif = 'Manifest berhasil diunduh ke folder : DOCUMENTS'
            $toast.add({
              severity: 'success', detail: notif, group: 'bc', life: 5000
            })
          } else {
            const fileURL = window.URL.createObjectURL(new Blob([result.result]))
            const fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', name)
            document.body.appendChild(fileLink)
            fileLink.click()
            // console.log('loadingManifestComposable download via web')
          }
        }
        if (result.isDenied) {
          const encryptData = encryptDecriptMethods.encrypt(val.ManifestId)
          const routeData = router.resolve({ name: 'report', params: { Id: encryptData, jenisReport: 'manifest' } })
          window.open(routeData.href, '_blank')
        }
      })
    }

    onMounted(() => {
      getAllData()
    })

    return {
      data,
      route,
      showDialogDetail,
      isRefund,
      idManifest,
      detail,
      getAllData,
      hideDialogDetail,
      expandedAccordion,
      getManifestDetail,
      printResi,
      canPrint,
      checkPermission,
      PermissionEnum
    }
  }
})
