
import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive,
  computed,
  defineComponent
} from 'vue'
import {
  transScheduleBusUseCase,
  departureProcessUseCase
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'
import moment from 'moment'
import {
  useRoute,
  useRouter
} from 'vue-router'
import {
  isEmpty
} from 'lodash'
import TabsDay from '@/views/transactions/delivery-item/create/steps-1/components/TabsDay.vue'
import {
  encryptDecriptMethods
} from '@/plugins/encryptDecriptMethods'
import { checkPermission } from '@/utils/helpers/checkPermission'
import PermissionEnum from '@/utils/enum/permissions'

export default defineComponent({
  name: 'PenjadwalanResiDialog',
  components: {
    TabsDay
  },
  props: {
    isShowDialog: {
      default: true
    },
    isRefund: {
      default: false
    },
    idTrans: {
      default: null
    },
    idJadwal: {
      default: null
    },
    detailResi: {
      default: null
    }
  },
  emits: ['hideDialog', 'reloadData', 'openJadwalResi'],
  setup(props: any, {
    emit
  }: any) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const app = getCurrentInstance()
    const {
      $toast,
      $confirm
    } = app!.appContext.config.globalProperties
    const isFromGuid = ref(false)
    const propertiesProps = reactive(props) as any
    const Id = () => encryptDecriptMethods.decrypt(route.params.id)
    const scheduleId = ref(null)
    const activeStyle = (idSchedule: any) => {
      if (idSchedule === scheduleId.value) {
        return 'color-orange-darken-4'
      }
      return ''
    }
    const hidedialogDetailResi = () => {
      // dialogDetailResi.value = false
      // emit('hideDialog')
      router.replace({
        name: 'departure-package'
      })
    }
    const data = ref({}) as any

    const dialogDetailResi = computed({
      get: () => propertiesProps.isShowDialog,
      set: (val: any) => {
        if (!val) {
          hidedialogDetailResi()
        }
      }
    })
    const onRefund = computed(() => propertiesProps.isRefund)
    const isValidData = computed(() => !isEmpty(data.value))
    const submitted = ref(false)

    const selectedSchedule = (idVal: any) => {
      scheduleId.value = scheduleId.value === idVal ? 0 : idVal
    }

    const listSchedule = ref([])
    const getScheduleBus = async (fromAgentId: any, idAgent: any, filterDay: any) => {
      store.dispatch('showLoading')
      const response = await transScheduleBusUseCase.searchJadwalBus(`?origAgentId=${fromAgentId}&destAgentId=${idAgent}&departureOffset=${filterDay}&pageSize=100&pageNumber=1`)

      if (!response.error) {
        let index = 1
        listSchedule.value = response.result.Data.map((val: any) => ({
          No: index++,
          Jadwal: val.Code,
          JadwalId: val.Id,
          JenisBus: val.BusCode,
          Keberangkatan: val.DepartureTime,
          Pendapatan: val.CurrentRevenue,
          Pengiriman: val.ArrivalTimeName,
          Rit: val.Rit,
          Attribute1: val.Attribute1
        }))
        store.dispatch('hideLoading')
      } else {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
        store.dispatch('hideLoading')
      }
      // store.dispatch('hideLoading')
    }
    const changeTabs = (tabValue: any) => {
      const arrHeader = ['0d', '1d', '2d']
      getScheduleBus(data.value.TransPengirimanHd.AgenAsal.Id, data.value.TransPengirimanHd.AgenTujuan.Id, arrHeader[tabValue])
    }

    const dataDetail = async () => {
      store.dispatch('showLoading')
      const response = await departureProcessUseCase.getDataForm(Id(), {
        expand: '&$expand=KurirMsUser($select=Username), TransPengirimanHd($expand=StatusPengiriman($select=Id,Status,Kode), AgenAsal($select=Id,Nama;$expand=Kota($select=Nama)), AgenTujuan($select=Id,Nama;$expand=Kota($select=Nama));$select=Id,AgenAsalId,AgenTujuanId,AlamatPenerima,AlamatPengirim,HpPenerima,HpPengirim,JenisPembayaran,Resi,TipePengiriman,TotalBerat,TotalColly,UpdatedAt,KategoriBarang,NamaPengirim,StatusPengirimanId,TransJadwalHdId)'
      })
      if (!response.error) {
        data.value = response.result
        scheduleId.value = response.result.TransPengirimanHd.TransJadwalHdId
        changeTabs(0)
      } else {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
      }
      store.dispatch('hideLoading')
    }

    const openManifestJadwal = (isFormValid: boolean) => {
      router.push({
        name: 'transaksi-manifest-pengiriman-detail',
        params: {
          id: data.value.TransJadwalHd.Id
        }
      })
    }

    const jadwalkanResi = () => {
      // if (data.value.TransPengirimanHd.TransJadwalHdId === scheduleId.value) {
      //   $toast.add({
      //     severity: 'error',
      //     detail: 'Jadwal baru tidak boleh sama dengan jadwal yang lama!',
      //     group: 'bc',
      //     life: 3000
      //   })
      // } else {
      //   store.dispatch('showLoading')
      //   departureProcessUseCase.assignSchedule({
      //     TransPengirimanHdId: data.value.TransPengirimanHdId,
      //     TransJadwalHdId: scheduleId.value
      //   }).then((response: any) => {
      //     if (!response.error) {
      //       router.push({
      //         name: 'scheduling-departure-package-step-2',
      //         params: {
      //           id: route.params.id
      //         }
      //       })
      //     } else {
      //       $toast.add({
      //         severity: 'error',
      //         detail: response.message,
      //         group: 'bc',
      //         life: 3000
      //       })
      //     }
      //     store.dispatch('hideLoading')
      //   })
      // }
      store.dispatch('showLoading')
      departureProcessUseCase.assignSchedule({
        TransPengirimanHdId: data.value.TransPengirimanHdId,
        TransJadwalHdId: scheduleId.value
      }).then((response: any) => {
        if (!response.error) {
          router.push({
            name: 'scheduling-departure-package-step-2',
            params: {
              id: route.params.id
            }
          })
        } else {
          $toast.add({
            severity: 'error',
            detail: response.message,
            group: 'bc',
            life: 3000
          })
        }
        store.dispatch('hideLoading')
      })
    }

    onMounted(() => {
      // if (propertiesProps.idTrans) {
      dataDetail()
      // }
    })

    return {
      hidedialogDetailResi,
      onRefund,
      dialogDetailResi,
      data,
      store,
      app,
      moment,
      submitted,
      openManifestJadwal,
      isFromGuid,
      isValidData,
      jadwalkanResi,
      changeTabs,
      listSchedule,
      activeStyle,
      scheduleId,
      selectedSchedule,
      PermissionEnum,
      checkPermission
    }
  }
})
