import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'

const baseUrl = '/backend/v2/agents'

const fetchAllAgent = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchSingleAgentByGuid = (guid: string) => new Remote(remoteEnum.get, `${baseUrl}/${guid}`)
const fetchSingleAgentById = (id: string) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const fetchAgentStockLabels = (id: number, filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/${id}/labelStocks${filter}`)
const addAgent = (data: any) => new Remote(remoteEnum.post, `${baseUrl}`, data)
const editAgent = (id: string, data: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const deleteAgent = (id: string) => new Remote(remoteEnum.delete, `${baseUrl}/${id}`)
const fetchAgentStickerStocks = (id: number, filter: any) => new Remote(remoteEnum.get, `${baseUrl}/${id}/stickerStocks${filter}`)

export {
  fetchAllAgent,
  fetchSingleAgentByGuid,
  fetchAgentStockLabels,
  addAgent,
  editAgent,
  deleteAgent,
  fetchAgentStickerStocks,
  fetchSingleAgentById
}
