import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'
import { TPayloadSaveUser } from './msUsers-model'

const baseUrl = '/identity/v2/msusers'

const fetchAllUsersV2 = (filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneUserV2 = (id: number, filter: any = '') => new Remote(remoteEnum.get, `${baseUrl}/${id}${filter}`)
const changeIsActiveuserV2 = (id: any, data: any) => new Remote(remoteEnum.patch, `${baseUrl}/${id}`, data)
const fetchAllUserAddress = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}/addresses`)
const fetchSingleUserAddress = (id: number, idAddress: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}/addresses/${idAddress}`)
const editOneUserV2 = (id: string, data: TPayloadSaveUser) => new Remote(remoteEnum.put, `${baseUrl}/${id}`, data)
const addOneUserV2 = (data: TPayloadSaveUser) => new Remote(remoteEnum.post, baseUrl, data)
const changeIsPrimaryCompanyAddressV2 = (idUser: number, idAddress: number) => new Remote(remoteEnum.put, `${baseUrl}/${idUser}/addresses/${idAddress}/setasprimary`)

export {
  changeIsPrimaryCompanyAddressV2,
  fetchAllUsersV2,
  fetchOneUserV2,
  changeIsActiveuserV2,
  fetchAllUserAddress,
  editOneUserV2,
  addOneUserV2,
  fetchSingleUserAddress
}
