
/* eslint-disable vue/no-dupe-keys */
import {
  getCurrentInstance,
  onMounted,
  reactive,
  ref,
  toRefs
} from 'vue'
import {
  historyComissionUseCase
} from '@/domain/usecase'
import CardComissionHistory from '@/views/master/courier/detail/comission-history/CardComissionHistory.vue'
import moment from 'moment'

export default {
  name: 'ComissionAdjustmentHistory',
  components: {
    CardComissionHistory
  },
  props: {
    dataAttr: {
      default: () => Object
    }
  },
  setup(props: any) {
    const app = getCurrentInstance()
    const {
      $toast,
      $strInd,
    } = app!.appContext.config.globalProperties
    const dataHistory = ref([])
    const {
      dataAttr
    } = toRefs(props)
    const dateOp = ref()
    const dateFilter = ref()
    const loading = ref(false)
    const isUsingFilter = ref(false)
    const filters = reactive({
      select: '&$select=Id,KurirTransUserPengirimanId,Nominal,IsPayment,Total,Attribute1,Attribute2,CreatedAt,Keterangan',
      // expand: '&$expand=KurirTransUserPengiriman($expand=KurirMsUser($select=Id),KurirTipePengiriman($select=Id,Keterangan,Kode),KurirMsJenisKendaraan($select=Id,Nama),TransPengirimanHd($select=Resi,Id); $select=Id,KurirMsUserId,TransPengirimanHdId,KurirTipePengirimanId,KurirMsJenisKendaraanId)',
      expand: '&$expand=KurirTransUserPengiriman($expand=KurirMsUser($select=Id),KurirMsJenisKendaraan($select=Id,Nama),TransPengirimanHd($select=Resi,Id); $select=Id,KurirMsUserId,TransPengirimanHdId,KurirTipePengirimanId,KurirMsJenisKendaraanId)',
      // eslint-disable-next-line quotes
      custom: `Attribute1 eq 'adjustment' and KurirMsUserId eq ${dataAttr.value.KurirId}`,
      sorting: 'CreatedAt desc',
      date: ''
    })
    const getDataComission = () => {
      loading.value = true
      historyComissionUseCase.getAll(filters).then((result: any) => {
        if (!result.error) {
          dataHistory.value = result.result
        } else {
          $toast.add({
            severity: 'error',
            detail: $strInd.toast.errorAllData,
            group: 'bc',
            life: 3000
          })
        }
        loading.value = false
      })
    }

    const saveFilterDate = async () => {
      // console.log('lkl', dateFilter.value.length)
      const filterDate = dateFilter.value.filter((x: any) => !x)
      if (filterDate.length === 0) {
        isUsingFilter.value = true
        filters.date = await `CreatedAt ge ${moment(dateFilter.value[0]).format('YYYY-MM-DD')} and CreatedAt le ${moment(dateFilter.value[1]).format('YYYY-MM-DD')}`
        await getDataComission()
        await dateOp.value.hide()
      } else {
        $toast.add({
          severity: 'error',
          detail: 'Gunakan range tanggal untuk filter',
          group: 'bc',
          life: 3000
        })
      }
      // if ()
    }

    const clearFilterDate = (evt: any) => {
      dateFilter.value = null
      filters.date = ''
      dateOp.value.hide()
      // arrivalOp.value.toggle(evt)
      getDataComission()
    }

    const showDateFilter = (evt: any) => {
      dateOp.value.toggle(evt)
    }
    onMounted(() => {
      getDataComission()
    })
    return {
      dataHistory,
      dataAttr,
      loading,
      dateOp,
      dateFilter,
      clearFilterDate,
      showDateFilter,
      saveFilterDate,
      isUsingFilter
    }
  }
}
