import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive,
  watch,
  computed
} from 'vue'
import {
  customerRetailUseCase,
  senderDataUseCase
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'
import useVuelidate from '@vuelidate/core'
import {
  required,
  requiredIf,
  requiredUnless,
  requiredRef,
  helpers,
  sameAs,
  not,
  and,
  withAsync
} from '@vuelidate/validators'
import { isNull } from 'lodash'
import moment from 'moment'

const modules = 'deliveryItem'

export default {
  name: 'FormSenderCompany',
  setup() {
    const store = useStore()
    const sendingCompanyOption = ref([])
    const companyAdressOption = ref([])
    const app = getCurrentInstance()
    const paymentTypeId = ref()
    const scheduleOption = ref([])
    const isDooring = ref(false)
    const isManualAddress = ref(false)
    const isAutoAdress = ref(true)
    const {
      $toast,
      $confirm
    } = app!.appContext.config.globalProperties
    const dataForm = reactive({
      sendingCompanyId: computed({
        get: () => store.state[modules].companyId,
        set: (val) => {
          store.dispatch(`${modules}/setCompanyId`, val)
          if (!isNull(val)) {
            // eslint-disable-next-line no-use-before-define
            getCompanyAddress(Array.isArray(val) ? val[0] : val)
            companyAdressOption.value = []
            if (dataForm.sendingAddressId) {
              store.state[modules].adressCompanyId = null
              store.state[modules].receiveAddressCompanyId = null
            }
            // console.log('test')
          } else {
            // eslint-disable-next-line no-use-before-define
            resetOption()
          }
        }
      }),
      sendingAddressId: computed(() => store.state[modules].adressCompanyId),
      receiverAddressId: computed(() => store.state[modules].receiveAddressCompanyId),
      addressSender: computed({
        get: () => store.state[modules].dataSender.addressSender,
        set: (val) => {
          // console.log('addr', val)
          store.dispatch(`${modules}/setDataSender`, {
            namaPengirim: store.state[modules].dataSender.nameSender,
            alamatPengirim: val,
            telpPengirim: store.state[modules].dataSender.telpSender,
            pickupScheduleId: store.state[modules].dataSender.pickupScheduleId
          })
        }
      }),
      pickupScheduleId: computed({
        get: () => store.state[modules].dataSender.pickupScheduleId,
        set: (val) => {
          // console.log('pickup', val)
          store.dispatch(`${modules}/setDataSender`, {
            namaPengirim: store.state[modules].dataSender.nameSender,
            alamatPengirim: store.state[modules].dataSender.addressSender,
            telpPengirim: store.state[modules].dataSender.telpSender,
            pickupScheduleId: val
          })
          // console.log('state', store.state[modules].dataSender)
        }
      }),
      telpSender: computed({
        get: () => store.state[modules].dataSender.telpSender,
        set: (val) => {
          store.dispatch(`${modules}/setDataSender`, {
            namaPengirim: store.state[modules].dataSender.nameSender,
            alamatPengirim: store.state[modules].dataSender.addressSender,
            telpPengirim: val,
            pickupScheduleId: store.state[modules].dataSender.pickupScheduleId
          })
        }
      }),
      nameSender: computed({
        get: () => store.state[modules].dataSender.nameSender,
        set: (val) => {
          store.dispatch(`${modules}/setDataSender`, {
            namaPengirim: val,
            alamatPengirim: store.state[modules].dataSender.addressSender,
            telpPengirim: store.state[modules].dataSender.telpSender,
            pickupScheduleId: store.state[modules].dataSender.pickupScheduleId
          })
        }
      }),
      nameReceiver: computed({
        get: () => store.state[modules].dataReceiver.nameReceiver,
        set: (val) => {
          store.dispatch(`${modules}/setDataReceiver`, {
            namaPenerima: val,
            alamatPenerima: store.state[modules].dataReceiver.addressReceiver,
            telpPenerima: store.state[modules].dataReceiver.telpReceiver
          })
        }
      }),
      telpReceiver: computed({
        get: () => store.state[modules].dataReceiver.telpReceiver,
        set: (val) => {
          store.dispatch(`${modules}/setDataReceiver`, {
            namaPenerima: store.state[modules].dataReceiver.nameReceiver,
            alamatPenerima: store.state[modules].dataReceiver.addressReceiver,
            telpPenerima: val,
            notesPenerima: store.state[modules].dataReceiver.notesReceiver
          })
        }
      }),
      addressReceiver: computed({
        get: () => store.state[modules].dataReceiver.addressReceiver,
        set: (val) => {
          store.dispatch(`${modules}/setDataReceiver`, {
            namaPenerima: store.state[modules].dataReceiver.nameReceiver,
            alamatPenerima: val,
            telpPenerima: store.state[modules].dataReceiver.telpReceiver
          })
        }
      }),
      notesReceiver: computed({
        get: () => store.state[modules].dataReceiver.notesReceiver,
        set: (val) => {
          store.dispatch(`${modules}/setDataReceiver`, {
            namaPenerima: store.state[modules].dataReceiver.nameReceiver,
            alamatPenerima: store.state[modules].dataReceiver.addressReceiver,
            telpPenerima: store.state[modules].dataReceiver.telpReceiver,
            notesPenerima: val
          })
        }
      }),
      notesSender: computed({
        get: () => store.state[modules].dataSender.notesSender,
        set: (val) => {
          store.dispatch(`${modules}/setDataSender`, {
            namaPengirim: store.state[modules].dataSender.nameSender,
            alamatPengirim: store.state[modules].dataSender.addressSender,
            telpPengirim: store.state[modules].dataSender.addressSender,
            notesPengirim: val
          })
        }
      })
    }) as any
    const submitted = ref(false)
    const isMiisi = computed(() => store.state[modules].isMiisi)

    // const checkAddressRecieverId = async (param: any) => {
    //   // let res = true
    //   // dataForm.receiverAddressId = 1
    //   console.log('')
    //   // if ((isAutoAdress.value === true && dataForm.addressReceiver.length === 0)) {
    //   //   res = false
    //   // } else if (isManualAddress.value === false) {
    //   //   res = true
    //   // }
    //   // console.log('res', res)
    //   return false
    // }

    const checkNotSameAddress = (param: any) => {
      let res = true
      // console.log('param', param)
      // console.log('sending', dataForm.sendingAddressId)
      // console.log('autoAddress', isAutoAdress.value)
      if (param !== 0 && dataForm.sendingAddressId !== null && param === dataForm.sendingAddressId && isAutoAdress.value) {
        res = false
      }
      // console.log('res', res)
      return res
    }

    const checkManualAddress = (param: any) => {
      let res = true
      // console.log('param', param.length)
      if (isManualAddress.value === true && param.length === 0) {
        res = false
      }
      // console.log('res', res)
      return res
    }

    const rules = {
      sendingCompanyId: {
        required: helpers.withMessage('Perusahaan Pengiriman harus diisi', required)
      },
      sendingAddressId: {
        required: helpers.withMessage('Pengirim harus diisi', required),
      },
      notesReceiver: {
        required: helpers.withMessage('Keterangan Alamat harus diisi', required)
      },
      nameReceiver: {
        required: helpers.withMessage('Penerima harus diisi', requiredIf(isManualAddress))
      },
      telpReceiver: {
        required: helpers.withMessage('No. Telp Penerima harus diisi', requiredIf(isManualAddress))
      },
      receiverAddressId: {
        // required: {
        //   $validator: checkAddressRecieverId,
        //   $message: 'Penerima harus diisi',
        //   $autoDirty: true
        // },
        mustNotSame: {
          $validator: checkNotSameAddress,
          $message: 'Pengirim dan penerima tidak boleh sama'
        },
      },
      addressReceiver: {
        // required: helpers.withMessage('Alamat Penerima harus diisi', requiredIf(isManualAddress.value)),
        required: {
          $validator: checkManualAddress,
          $message: 'Alamat Penerima harus diisi'
        },
      },
      // notesSender: {
      //   required: helpers.withMessage('Keterangan Alamat harus diisi', required)
      // },
      nameSender: {
        required: helpers.withMessage('Pengirim harus diisi', required)
      },
      telpSender: {
        required: helpers.withMessage('No. Telp Pengirim harus diisi', required)
      }
    }

    const v$ = useVuelidate(rules, dataForm)

    const getOptionCompany = async () => {
      const response = await senderDataUseCase.getSelectDataCompany({
        custom: [['IsActive', '=', true], ['CompanyName', '=', '']]
      })
      // console.log(response.result.Data)
      sendingCompanyOption.value = await response.result.map((x: any) => ({
        label: x.Kota === null ? x.Fullname : `${x.Fullname} - ${x.Kota}`,
        value: [x.LoginId, x.Username]
      }))
    }

    const getCompanyAddress = async (idCompany: any) => {
      // console.log('idCompany', idCompany)
      const response = await senderDataUseCase.getSelectCompanyAddress({
        companyId: idCompany
      })
      // console.log(response.result)
      companyAdressOption.value = await response.result.map((x: any) => ({
        label: x.Alamat1,
        value: x.Id
      }))
      // console.log(companyAdressOption.value)
    }

    const getSpecificCompany = async (idCompany: any) => {
      // console.log('id:', idCompany)
      store.dispatch('showLoading')
      const response = await senderDataUseCase.getSpecificCompany(idCompany)
      const data = response.result
      store.dispatch(`${modules}/setDataSender`, {
        namaPengirim: data.Nama,
        alamatPengirim: data.Alamat,
        telpPengirim: data.Telepon
      })
      dataForm.addressSender = data.Alamat
      dataForm.nameSender = data.Nama
      dataForm.telpSender = data.Telepon
      store.dispatch('hideLoading')
    }

    const getSpecificAddress = async (idAddress: any) => {
      // console.log('getSpesificAddress', idAddress)
      store.dispatch('showLoading')
      const response = await senderDataUseCase.getSpecificCompanyAddress(dataForm.sendingCompanyId[0], idAddress)
      const data = response.result
      // console.log('data', data)
      store.dispatch(`${modules}/setDataReceiver`, {
        namaPenerima: data.NamaPenerima,
        alamatPenerima: data.Alamat1,
        telpPenerima: data.HpPenerima
      })
      dataForm.addressReceiver = data.Alamat1
      dataForm.nameReceiver = data.NamaPenerima
      dataForm.telpReceiver = data.HpPenerima
      store.dispatch('hideLoading')
    }

    const getSpecificSenderAddress = async (idAddress: any) => {
      // console.log('getspesificadd', idAddress)
      store.dispatch('showLoading')
      const response = await senderDataUseCase.getSpecificCompanyAddress(dataForm.sendingCompanyId[0], idAddress)
      const data = response.result
      // console.log(data)
      store.dispatch(`${modules}/setDataSender`, {
        namaPengirim: data.NamaPenerima,
        alamatPengirim: data.Alamat1,
        telpPengirim: data.HpPenerima
      })
      dataForm.addressSender = data.Alamat1
      dataForm.nameSender = data.NamaPenerima
      dataForm.telpSender = data.HpPenerima
      store.dispatch('hideLoading')
    }

    const updateValueAction = (val: any) => {
      // console.log('val', val)
      if (val) {
        store.dispatch(`${modules}/setCompanyId`, val)
        getCompanyAddress(val[0])
        getSpecificCompany(val[0])
      }
    }

    const updateValueAddress = (val: any) => {
      if (val) {
        store.dispatch(`${modules}/setCompanyReceiveAddressId`, val)
        getSpecificAddress(val)
      }
    }

    const updateReceive = (val: any) => {
      if (val) {
        // console.log('updateRecieve')
        store.dispatch(`${modules}/setCompanyAddressId`, val)
        getSpecificSenderAddress(val)
      }
    }
    if (dataForm.sendingCompanyId) {
      // console.log('X', dataForm.sendingCompanyId)
      getCompanyAddress(Array.isArray(dataForm.sendingCompanyId) ? dataForm.sendingCompanyId[0] : dataForm.sendingCompanyId)
    }

    const resetOption = () => {
      sendingCompanyOption.value = []
      companyAdressOption.value = []
      // dataForm.sendingCompanyId = null
    }

    onMounted(() => {
      // eslint-disable-next-line no-use-before-define
      getOptionSchedule()
      getOptionCompany()
      // eslint-disable-next-line no-use-before-define
      getDefaultPaymentType()
      // resetOption()
      // eslint-disable-next-line no-use-before-define
      checkRoute()
      if (!dataForm.receiverAddressId) {
        isManualAddress.value = true
      }
    })

    const prosesSubmit = async () => {
      // console.log('receiver', dataForm.addressReceiver)
      store.dispatch('showLoading')
      const data = {
        AgenAsalId: store.getters['appActiveUser/getAgent'].id,
        AgenTujuanId: store.state[modules].toAgentId,
        NamaPengirim: dataForm.nameSender,
        NamaPenerima: dataForm.nameReceiver,
        AlamatPengirim: dataForm.addressSender,
        AlamatPenerima: dataForm.addressReceiver,
        TipePengiriman: store.state[modules].typeDelivery,
        HpPengirim: dataForm.telpSender,
        HpPenerima: dataForm.telpReceiver,
        Username: Array.isArray(dataForm.sendingCompanyId) ? dataForm.sendingCompanyId[1] : dataForm.sendingCompanyId,
        PelangganId: null,
        TransJadwalHdId: store.state[modules].scheduleId,
        KeteranganPenerima: dataForm.notesReceiver,
        JenisPembayaranId: paymentTypeId.value,
        TransJadwalKurirId: dataForm.pickupScheduleId === 0 ? null : dataForm.pickupScheduleId
      }
      const result = await senderDataUseCase.newSubmitData(data, store.state[modules].dataAfterPost ? store.state[modules].dataAfterPost.Id : null)
      if (!result.error) {
        // store.dispatch(`${modules}/setDataAfterPost`, result.result)
        store.dispatch('hideLoading')
        store.dispatch(`${modules}/setCurrentIndex`, 2)
      } else {
        store.dispatch('hideLoading')
        $toast.add({
          severity: 'error',
          detail: result.message,
          group: 'bc',
          life: 3000
        })
      }
    }

    const getDefaultPaymentType = async () => {
      const response = await senderDataUseCase.getDefaultPaymentType()
      const data = response.result
      // console.log(data[0].Id)
      paymentTypeId.value = data[0].Id
    }

    const submitData = (isFormValid: boolean) => {
      // store.dispatch(`${modules}/setDataReceiver`, {
      //   namaPenerima: dataForm.nameReceiver,
      //   alamatPenerima: dataForm.addressReceiver,
      //   telpPenerima: dataForm.telpReceiver
      // })
      // store.dispatch(`${modules}/setDataSender`, {
      //   namaPengirim: dataForm.nameSender,
      //   alamatPengirim: dataForm.addressSender,
      //   telpPengirim: dataForm.telpSender
      // })
      submitted.value = true
      if (!isFormValid) {
        return
      }
      prosesSubmit()
    }

    const submitBack = () => {
      store.dispatch(`${modules}/setCurrentIndex`, 0)
    }

    const getOptionSchedule = async () => {
      moment.locale('id')
      const response = await senderDataUseCase.getOptionSchedule()
      // console.log('res', response)
      scheduleOption.value = response.result.map((x: any) => ({
        label: `${moment(x.WaktuMulaiPenjemputan).format('D MMMM YYYY')} ${moment(x.WaktuMulaiPenjemputan).format('HH:mm')} WIB - ${moment(x.WaktuAkhirPenjemputan).format('HH:mm')} WIB`,
        value: x.Id
      }))
      // console.log('val', scheduleOption.value)
    }

    const checkRoute = () => {
      const currType = store.state[modules].typeDelivery
      if (currType === 'DA' || currType === 'DD') {
        isDooring.value = true
      }
    }

    const changeInputRecieverAddress = async () => {
      // console.log('aaa')
      // isManualAddress.value = !isManualAddress.value
      isAutoAdress.value = !isAutoAdress.value
      // console.log('auto', isAutoAdress.value)
      // console.log('manual', isManualAddress.value)
    }

    // watch(isManualAddress, () => {
    //   // console.log('isManual', isManualAddress.value)
    //   store.state[modules].receiveAddressCompanyId = 0
    //   console.log(dataForm.receiverAddressId)
    //   console.log(dataForm.sendingAddressId)
    // })
    return {
      sendingCompanyOption,
      dataForm,
      getOptionCompany,
      updateValueAction,
      companyAdressOption,
      store,
      getSpecificAddress,
      updateValueAddress,
      submitData,
      submitBack,
      v$,
      rules,
      submitted,
      app,
      updateReceive,
      scheduleOption,
      isDooring,
      isManualAddress,
      changeInputRecieverAddress,
      isAutoAdress,
      isMiisi
    }
  }
}
