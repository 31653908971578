import { getAllTypeBusFlow, dataEditIsActive, deleteTypeBus } from '@/domain/usecase'
import { onMounted, ref, getCurrentInstance } from 'vue'
import TypeBusForm from '@/views/master/transactions/type-bus/TypeBusForm.vue'
import DropdownAbsolute from '@/views/shared-components/DropdownAbsolute.vue'
import DropdownMenu from '@innologica/vue-dropdown-menu'
import { useStore } from 'vuex'

export default {
  components: {
    TypeBusForm,
    DropdownAbsolute,
    DropdownMenu
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $confirm, $toast, $strInd, $numbers
    } = app!.appContext.config.globalProperties
    const data = ref([])
    const idBus = ref('')
    const sliderActive = ref(false)
    const store = useStore()
    const op = ref()
    const inputSearch = ref('')
    const filters = {
      search: '',
      totalRecords: 0,
      skip: 0,
      top: $numbers.totalItemPerPage,
      order: 'CreatedAt  desc'
    }
    // CreateDate

    const redirectAdd = () => {
      idBus.value = ''
      sliderActive.value = true
    }

    const redirectEdit = (id: string) => {
      idBus.value = id
      sliderActive.value = true
    }

    const showDropDown = (evt: any, id: any) => {
      idBus.value = id
      op.value.toggle(evt)
    }

    const closeSlidebar = () => {
      sliderActive.value = false
    }

    const getAllTypeBus = async (search = '') => {
      store.dispatch('showLoading')
      filters.search = search
      const response = await getAllTypeBusFlow(filters)
      if (!response.error) {
        data.value = response.result
        filters.totalRecords = response.count
      }
      sliderActive.value = false
      store.dispatch('hideLoading')
    }

    onMounted(() => {
      getAllTypeBus()
    })

    const inActived = async (val: any) => {
      store.dispatch('showLoading')
      const response = await dataEditIsActive({
        Id: val.Id,
        IsActive: val.IsActive
      })

      if (response.error) {
        val.IsActive = !val.IsActive
        $toast.add({
          severity: 'error', detail: 'Gagal mengubah status', group: 'bc', life: 3000
        })
      } else {
        $toast.add({
          severity: 'success', detail: 'Status berhasil diubah', group: 'bc', life: 3000
        })
      }
      store.dispatch('hideLoading')
    }

    const changeIsActive = (val: any) => {
      $confirm.require({
        header: 'Status Aktif',
        message: 'Apakah anda ingin menggantinya?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          inActived(val)
        },
        reject: () => {
          val.IsActive = !val.IsActive
        }
      })
    }

    const deleteData = (idData:any) => {
      $confirm.require({
        header: 'Bagasi',
        message: 'Apakah anda yakin ingin menghapusnya?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await deleteTypeBus(idData)
          if (response.error) {
            $toast.add({
              severity: 'error', detail: 'Gagal menghapus diskon retail, silahkan coba kembali', group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: $strInd.toast.successDeleteData, group: 'bc', life: 3000
            })
            getAllTypeBus()
          }
        }
      })
    }

    return {
      op,
      idBus,
      data,
      sliderActive,
      inputSearch,
      getAllTypeBus,
      redirectEdit,
      redirectAdd,
      closeSlidebar,
      changeIsActive,
      showDropDown,
      deleteData
    }
  }
}
