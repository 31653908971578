import {
  ref, onMounted, getCurrentInstance, reactive, computed,
  defineComponent
} from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { deliveryManifestUseCase, reportUseCase } from '@/domain/usecase'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { fileWrite } from '@/plugins/system/filesystem'
import { isMobile } from '@/plugins/system/device'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'

/* eslint-disable */
export default defineComponent({
  props: {
    detail: {
      default: {}
    }
  },
  setup(props) {
    const modules = 'manifestDetail'
    const app = getCurrentInstance()
    const { $toast, $getDiffDate, $swal } = app!.appContext.config.globalProperties
    const propertiesProps = reactive(props)
    const det = computed(() => propertiesProps.detail) as any
    const data = ref([]) as any
    const checkedAllValue = ref(false)
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const op = ref()
    const isRefund = ref(false)
    const showDialogDetail = ref(false)
    const dialogPending = ref(false)
    const dialogLoadingManifest = ref(false)
    const inputSearch = ref('')
    const selectedReceipt = ref([]) as any
    const submitted = ref(false)
    const submittedPend = ref(false)
    const isDownloadFile = ref(false)
    const profileAgent = store.getters['appActiveUser/getAgent']
    const dataPending = reactive({
      description: ''
    })
    const dataAdd = reactive({
      noPol: '',
      driverName: '',
      stewardName: '',
      kruName: null,
      description: ''
    })
    const detailAdd = ref({}) as any
    const rules = {
      noPol: {
        required: helpers.withMessage('Nomor polisi Bus harus diisi', required)
      },
      stewardName: {
        required: helpers.withMessage('Nama pramugara harus diisi', required)
      },
      driverName: {
        required: helpers.withMessage('Nama supir harus diisi', required)
      }
    }
    const rulesPending = {
      description: {
        required: helpers.withMessage('Keterangan harus diisi', required)
      }
    }

    const v$ = useVuelidate(rules, dataAdd)
    const vPend$ = useVuelidate(rulesPending, dataPending)
    const idManifest = ref({})

    const getAllData = async (search: any = '') => {
      store.dispatch('showLoading')
      checkedAllValue.value = false
      selectedReceipt.value = []
      const response = await deliveryManifestUseCase.getDepatureManifest(route.params.id, profileAgent?.id)
      if (!response.error) {
        data.value = response.result.map((item: any) => {
          item.checked = false
          return item
        })
      }
      store.dispatch('hideLoading')
    }

    const getDetailLoadingManifest = async () => {
      store.dispatch('showLoading')
      const dataForm = {
        PengirimanHdId: selectedReceipt.value,
        AgenId: profileAgent?.id
      }
      const response = await deliveryManifestUseCase.submitLoadingManifest(dataForm)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
      } else {
        detailAdd.value = response.result
        dataAdd.noPol = response.result.DataTerakhir.Nopol
        dataAdd.stewardName = response.result.DataTerakhir.NamaPramugara
        dataAdd.driverName = response.result.DataTerakhir.NamaSupir
      }
      store.dispatch('hideLoading')
    }

    const searchData = (search: any) => {
      getAllData(search)
    }

    const showDialogPending = () => {
      if (selectedReceipt.value.length <= 0) {
        $toast.add({
          severity: 'error', detail: 'Silahkan untuk pilih manifest terlebih dahulu', group: 'bc', life: 1500
        })
      } else {
        dialogPending.value = true
      }
    }

    const hideDialogPending = () => {
      dataPending.description = ''
      dialogPending.value = false
    }

    const showDialogLoadingManifest = () => {
      if (selectedReceipt.value.length <= 0) {
        $toast.add({
          severity: 'error', detail: 'Silahkan untuk pilih manifest terlebih dahulu', group: 'bc', life: 1500
        })
      } else {
        dialogLoadingManifest.value = true
        getDetailLoadingManifest()
      }
    }

    const hideDialogLoadingManifest = () => {
      dialogLoadingManifest.value = false
    }
    // converter base64
    const toBase64 = (file: any) => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

    const proccessLoadingManifest = async () => {
      store.dispatch('showLoading')
      const dataSubmit = {
        // TransJadwalHdId: route.params.id,
        licensePlateNumber: dataAdd.noPol,
        driverHelperName: dataAdd.stewardName,
        driverName: dataAdd.driverName,
        comment: dataAdd.description,
        deliveryIds: selectedReceipt.value
      }
      console.log('dataSubmit', dataSubmit)
      const response = await deliveryManifestUseCase.submitData(null, dataSubmit)
      console.log('ini resposne delivery Manifest', response)
      if (response.error) {
        store.dispatch('hideLoading')
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
      } else {
        // store.dispatch(`${modules}/setDataLastManifest`, response.result)
        // console.log('log', response.result)
        const encryptData = encryptDecriptMethods.encrypt(response.result.Id)
        localStorage.setItem('lastManifest', JSON.stringify([encryptData, response.result.NoManifest]))
        $toast.add({
          severity: 'success', detail: 'Berhasil disimpan', group: 'bc', life: 1500
        })
        dialogLoadingManifest.value = false
        getAllData()
        if (isDownloadFile.value) {
          const result = await reportUseCase.getPdfManifest(Number(response.result.Id))
          const name = `${response.result.Code}.pdf`
          let notif = null

          if (await isMobile()) {
            const file = await toBase64(new Blob([result.result]))
            await fileWrite(name, file)
            // console.log('depatureManifestComposable download via android')
            notif = 'Resi berhasil diunduh ke folder : DOCUMENTS '
            $toast.add({
              severity: 'success', detail: notif, group: 'bc', life: 5000
            })
          } else {
            const fileURL = window.URL.createObjectURL(new Blob([result.result]))
            const fileLink = document.createElement('a')
            fileLink.href = fileURL
            const name = `${response.result.Code}.pdf`
            fileLink.setAttribute('download', name)
            document.body.appendChild(fileLink)
            fileLink.click()
            // console.log('depatureManifestComposable download via web')
          }
        } else {
          console.log('test')
          const encryptData = encryptDecriptMethods.encrypt(response.result.Id)
          const routeData = router.resolve({ name: 'report', params: { Id: encryptData, jenisReport: 'manifest' } })
          window.open(routeData.href, '_blank')
        }
      }
    }

    const submitLoadingManifest = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      // $confirm.require({
      //   header: 'Berangkatkan Loading Manifest',
      //   message: 'Pastikan resi yang disimpan sudah sesuai dengan barang yang sudah dimuat oleh kru dan supir bis. Resi yang sudah diinput tidak dapat diubah lagi.',
      //   icon: 'pi pi-exclamation-triangle',
      //   acceptLabel: 'Simpan & Print',
      //   rejectLabel: 'Kembali',
      //   accept: () => {
      //     proccessLoadingManifest()
      //   }
      // })
      $swal.fire({
        text: 'Pastikan resi yang disimpan sudah sesuai dengan barang yang sudah dimuat oleh kru dan supir bis. Resi yang sudah diinput tidak dapat diubah lagi.',
        title: 'Berangkatkan Loading Manifest',
        imageUrl: require('@/assets/img/icon/printer.svg'),
        imageWidth: 400,
        imageHeight: 150,
        imageAlt: 'Custom image',
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonColor: '#ff9800',
        cancelButtonColor: '#F44336',
        denyButtonColor: '#90A4AE',
        allowOutsideClick: false,
        confirmButtonText: 'Simpan & Download',
        cancelButtonText: 'Kembali',
        denyButtonText: 'Simpan & Tampilkan'
      }).then(async (result: any) => {
        // console.log('resultcompo', result)
        if (result.isConfirmed) {
          // eslint-disable-next-line no-shadow
          isDownloadFile.value = true
          proccessLoadingManifest()
        }
        if (result.isDenied) {
          isDownloadFile.value = false
          proccessLoadingManifest()
        }
      })
    }

    const proccessPendingManifest = async () => {
      store.dispatch('showLoading')
      const dataForm = {
        data: selectedReceipt.value.map((x: any) => ({
          id: x
        })),
        note: dataPending.description,
        action: 'Pending'
      }

      const response = await deliveryManifestUseCase.submitPendingManifest(dataForm)
      if (response.error) {
        store.dispatch('hideLoading')
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          severity: 'success', detail: 'Berhasil disimpan', group: 'bc', life: 1500
        })
        hideDialogPending()
        getAllData()
      }
    }

    const submitPendingManifest = (isFormValid: boolean) => {
      submittedPend.value = true
      if (!isFormValid) {
        return
      }
      proccessPendingManifest()
    }

    const activeStyle = (id: any) => {
      if (selectedReceipt.value.includes(id)) {
        return {
          backgroundColor: '#FFF3E0'
        }
      }
      return {}
    }

    const pushDataSelected = (isSelected: any, id: any) => {
      if (isSelected) {
        selectedReceipt.value.push(id)

      } else {
        const index = selectedReceipt.value.indexOf(id)
        if (index > -1) {
          selectedReceipt.value.splice(index, 1)
        }
      }
    }

    const checkedAll = () => {
      for (let i = 0; i < data.value.length; i++) {
        data.value[i].checked = checkedAllValue.value
        pushDataSelected(checkedAllValue.value, data.value[i].Id)
      }
    }

    const changeChecked = (id: any, val: any) => {
      for (let i = 0; i < data.value.length; i++) {
        if (data.value[i].Id === id) {
          data.value[i].checked = val
          pushDataSelected(val, id)
          break
        }
      }
    }

    const showDropDown = (evt: any, item: any) => {
      idManifest.value = item
      op.value.toggle(evt)
    }

    const refund = (val: any) => {
      showDialogDetail.value = true
      isRefund.value = true
    }

    const detail = (val: any) => {
      idManifest.value = val
      showDialogDetail.value = true
      isRefund.value = false
    }

    const departureTolerance = store.getters['globalSetting/departureTolerence']

    const checkDateDepature = () => {
      if (det.value) {
        if (det.value.TransJadwalDetails.length > 0) {
          const diff = $getDiffDate(det.value.TransJadwalDetails[0].WaktuKeberangkatan, Number(departureTolerance.value))
          // console.log(Number(store.state.toleranceSchedule.value))
          return diff <= 0
          // return $getDiffDate(det.value.TransJadwalDetails[0].WaktuKeberangkatan, Number(store.state.toleranceSchedule.value))
        }
      }
      return false
    }

    onMounted(() => {
      getAllData()
    })

    return {
      op,
      data,
      det,
      inputSearch,
      route,
      dialogPending,
      dialogLoadingManifest,
      checkedAllValue,
      selectedReceipt,
      dataAdd,
      detailAdd,
      vPend$,
      v$,
      submitted,
      submittedPend,
      idManifest,
      isRefund,
      showDialogDetail,
      checkDateDepature,
      getAllData,
      refund,
      detail,
      showDropDown,
      submitLoadingManifest,
      searchData,
      getDetailLoadingManifest,
      submitPendingManifest,
      checkedAll,
      activeStyle,
      changeChecked,
      showDialogPending,
      hideDialogPending,
      showDialogLoadingManifest,
      hideDialogLoadingManifest
    }
  }
})
