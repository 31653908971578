import {
  ref, onMounted, getCurrentInstance, reactive, watch, computed
} from 'vue'
import { useStore } from 'vuex'
/* eslint-disable-next-line import/named */
import { transScheduleBusUseCase } from '@/domain/usecase'
import ItemRevenueIsWaitingCard from '@/views/cashier/ItemRevenueIsWaitingCard.vue'
import ItemRevenueIsDoneCard from '@/views/cashier/ItemRevenueIsDoneCard.vue'
import moment from 'moment'

export default {
  components: {
    ItemRevenueIsWaitingCard,
    ItemRevenueIsDoneCard
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $numbers, $toast, $strInd, $confirm
    } = app!.appContext.config.globalProperties
    const inputSearch = ref('')
    const data = ref([])
    const dataRevenueIsDone = ref([])
    const store = useStore()
    const tabsHeader = ref([{
      key: 0,
      title: 'Menunggu Pengecekan'
    },
    {
      key: 1,
      title: 'Selesai Diproses'
    }
    ])
    const indexTab = ref(0) as any
    const ritOption = [
      // { name: 'AKAP', value: '3' },
      // { name: 'AKDP', value: '4' },
      { name: 'RIT 1', value: '1' },
      { name: 'RIT 2', value: '2' }
    ]
    const sortingOption = ref([
      {
        value: 'TanggalAktif desc',
        name: 'Tanggal Aktif'
      },
      {
        value: 'UpdatedAt desc',
        name: 'Terakhir Update'
      },
      // {
      //   value: 'Jadwal desc',
      //   name: 'Jadwal'
      // },
      // {
      //   value: 'Total PendapatanAgen desc',
      //   name: 'Total Pendapatan Agent'
      // }
    ])
    const sortBy = ref('Id desc')
    const sortOp = ref()
    const dateOp = ref()
    const ritFilter = ref('')
    const cmpSearch = ref()
    const dateFilter: any = ref(null)
    const filters = reactive({
      skip: 0,
      top: $numbers.totalItemPerPage,
      totalRecords: 0,
      search: inputSearch.value,
      tanggalAktif: dateFilter.value,
      rit: ritFilter.value,
      orderBy: sortBy.value,
      isClose: false,
      hasTransPengirimanHds: true,
      expand: 'TipeBus($select=Kode,Nama),CloseBy,TransPengirimanHds($select=Id;$expand=AgenAsal($select=Kode), AgenTujuan($select=Kode), Manifest), TransPengirimanHds($count=true)'
    })
    const agentOp = ref()
    const arrivalOp = ref()
    const listChecked = ref([]) as any
    /* eslint-disable consistent-return */
    /* eslint-disable no-useless-return */
    /* eslint no-else-return: ["error", {allowElseIf: true}] */
    const filterRit = () => {
      if (ritFilter.value.length === 1) {
        return `Rit in ('${ritFilter.value[0]}') and`
      } else if (ritFilter.value.length === 2) {
        return `Rit in ('${String(ritFilter.value[0])}','${String(ritFilter.value[1])}') and`
      } return ''
    }

    const filterTanggal = () => {
      if (dateFilter.value !== null) {
        return `TanggalAktif eq ${moment(dateFilter.value).format('YYYY-MM-DD')} and `
      } return ''
    }
    const getAllData = async (search: any = '') => {
      store.dispatch('showLoading')
      filters.search = search
      filters.isClose = (indexTab.value === 1)
      if (search !== '') {
        filters.skip = 0
      }

      const response = await transScheduleBusUseCase.getAllVwPenutupanKasir({
        top: filters.top,
        skip: filters.skip,
        search: inputSearch.value,
        // expand: '&$expand=TipeBus($select=Kode,Nama),CloseBy($select=Id,NamaLengkap),TransPengirimanHds($select=Id;$expand=AgenAsal($select=Kode), AgenTujuan($select=Kode), Manifest($select=Id,NoManifest)), TransPengirimanHds($count=true)',
        custom: `${filterTanggal()}${filterRit()} IsClose eq ${filters.isClose}`,
        orderBy: sortBy.value
      })
      if (!response.error) {
        if (indexTab.value === 0) {
          filters.totalRecords = response.count
          data.value = response.result
        } else if (indexTab.value === 1) {
          filters.totalRecords = response.count
          dataRevenueIsDone.value = response.result
        }
      } else {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    const searchData = (search: any) => {
      getAllData(search)
    }
    const saveFilterDate = (evt: any) => {
      // arrivalOp.value.toggle(evt)
      filters.tanggalAktif = moment(dateFilter.value).format('YYYY-MM-DD')
      getAllData(filters.search)
    }
    const showSortFilter = (evt: any) => {
      // console.log('showSortFilter', evt)
      sortOp.value.toggle(evt)
    }
    const showDateFilter = (evt: any) => {
      dateOp.value.toggle(evt)
    }

    const clearFilterDate = (evt: any) => {
      dateFilter.value = null
      filters.tanggalAktif = null
      dateOp.value.hide()
      // arrivalOp.value.toggle(evt)
      getAllData(filters.search)
    }

    const onPage = (event: any) => {
      filters.skip = event.page * filters.top
      getAllData()
    }

    const changeSortBy = (evt: any) => {
      // console.log('changeSortBy', evt)
      filters.orderBy = sortBy.value
      getAllData()
    }

    watch([ritFilter], () => {
      if (ritFilter.value) {
        filters.rit = ritFilter.value
        getAllData()
      }
    })
    watch(indexTab, () => {
      if (indexTab) {
        inputSearch.value = ''
        cmpSearch.value.reset()
        getAllData()
      }
    })

    onMounted(() => {
      getAllData()
    })
    return {
      data,
      dataRevenueIsDone,
      moment,
      inputSearch,
      filters,
      agentOp,
      arrivalOp,
      sortOp,
      dateOp,
      listChecked,
      onPage,
      getAllData,
      searchData,
      clearFilterDate,
      saveFilterDate,
      tabsHeader,
      indexTab,
      ritOption,
      ritFilter,
      showSortFilter,
      showDateFilter,
      dateFilter,
      sortingOption,
      sortBy,
      cmpSearch,
      changeSortBy,
    }
  }
}