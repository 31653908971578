import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'

const baseUrl = '/backend/v2/deliveries'

const fetchAllDeliveries = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}${filter}`)
const fetchOneDeliveryById = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}`)
const fetchOneDeliveryByGuid = (guid: string) => new Remote(remoteEnum.get, `${baseUrl}/${guid}`)
const fetchAllDeliveriesCountByStatus = (filter = '') => new Remote(remoteEnum.get, `${baseUrl}/countbyStatus${filter}`)
const fetchDeliveryStatusLogs = (id: number | string) => new Remote(remoteEnum.get, `${baseUrl}/${id}/deliverystatuslogs`)
// const fetchDeliveryStatusLogsById = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}/deliverystatuslogs`)
// const fetchDeliveryStatusLogsByGuid = (guid: string) => new Remote(remoteEnum.get, `${baseUrl}/${guid}/deliverystatuslogs`)
const fetchDeliveryAdditionalCosts = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}/additionalcosts`)
const fetchDeliveryLabels = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}/labels`)
const fetchDeliveryReception = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}/reception`)
const fetchDeliveryReturnRequest = (id: number) => new Remote(remoteEnum.get, `${baseUrl}/${id}/returnrequest`)

const editDeliveryReturnRequest = (id: number, payload: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}/requestreturn`, payload)
const editDeliveryApproveReturn = (id: number, payload: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}/approvereturn`, payload)
const editDeliveryDeclineReturn = (id: number, payload: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}/declinereturn`, payload)
const editDeliveryCancelRequest = (id: number, payload: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}/requestcancel`, payload)
const editDeliveryCancelAcknowledge = (id: number, payload: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}/acknowledgecancel`, payload)
const editDeliveryApproveCancel = (id: number, payload: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}/approvecancel`, payload)
const editDeliveryDeclineCancel = (id: number, payload: any) => new Remote(remoteEnum.put, `${baseUrl}/${id}/declinecancel`, payload)
const updateBulkDelivery = (data: any) => new Remote(remoteEnum.put, `${baseUrl}/_bulk`, data)

export {
  fetchAllDeliveries,
  fetchOneDeliveryById,
  fetchOneDeliveryByGuid,
  fetchAllDeliveriesCountByStatus,
  fetchDeliveryStatusLogs,
  fetchDeliveryAdditionalCosts,
  fetchDeliveryLabels,
  fetchDeliveryReception,
  fetchDeliveryReturnRequest,

  editDeliveryReturnRequest,
  editDeliveryApproveReturn,
  editDeliveryDeclineReturn,
  editDeliveryCancelRequest,
  editDeliveryCancelAcknowledge,
  editDeliveryApproveCancel,
  editDeliveryDeclineCancel,
  updateBulkDelivery
}
